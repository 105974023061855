import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Card, Divider } from 'semantic-ui-react';
import { useStudents } from '../../students/studentsHooks';
import { CONTACT_STATUS, EnrollmentStatusEnum } from '@wonderschool/common-base-types';
import { useContacts } from '../../contacts';

// utils
enum StatsEnum {
  CHECKEDIN = 'checkedIn',
  STUDENTS = 'students',
  ROOMS = 'rooms',
  STAFF = 'staff',
  TRANSACTIONS = 'transactions',
  CLOCKEDIN = 'clockedIn',
}

interface LocationStatsProps {
  stats: {
    [key in StatsEnum]?: any;
  };
}

type LocationOverviewItemProps = {
  title: string;
  value: number;
  highlight?: boolean;
};
const LocationOverviewItem = ({ title, value, highlight }: LocationOverviewItemProps) => (
  <div
    className={
      'ml-0 mr-auto flex space-x-2 text-nowrap border-none py-1.5' +
      (highlight === true ? ' rounded-lg border bg-purple-50 px-3' : '')
    }
  >
    <span>{title}:</span>
    <strong>{value}</strong>
  </div>
);

const LocationOverviewTable: React.FC<LocationStatsProps> = ({ stats = {} }) => {
  const { t } = useTranslation();
  const studentsFilter = {
    activeTab: EnrollmentStatusEnum.ENROLLED,
    searchText: '',
    filters: {
      enrollmentStatus: EnrollmentStatusEnum.ENROLLED,
      hideArchivedStudents: true,
    },
  };
  const students = useStudents(studentsFilter);
  const enrolledStudentsCount = students.list.filter((student) => student.enrollmentStatus).length;
  const checkedInStudents = students.list.filter((student) => student.studentState?.includes('checked-in')).length;
  const { staffContacts } = useContacts();
  const activeStaffContactsCount = staffContacts.filter((staff) => staff.status === CONTACT_STATUS.verified).length;

  if (!stats) return null;

  const clockedInStaff = stats[StatsEnum.CLOCKEDIN]?.total ?? 0;

  return (
    <div className="flex flex-wrap gap-x-6">
      <div className="flex flex-col">
        <LocationOverviewItem title={t('overview.totalStudents')} value={enrolledStudentsCount} />
        <LocationOverviewItem title={t('overview.checkedInStudents')} value={checkedInStudents} highlight />
      </div>
      <div className="flex flex-col">
        <LocationOverviewItem title={t('overview.totalStaff')} value={activeStaffContactsCount} />
        <LocationOverviewItem title={t('overview.clockedInStaff')} value={clockedInStaff} highlight />
      </div>
    </div>
  );
};

const LocationStatsOverview: React.FC<LocationStatsProps> = ({ stats = {} }) => {
  const { t } = useTranslation();

  return (
    <>
      <Card.Header as="h3">{t('overview.schoolOverview')}</Card.Header>
      <LocationOverviewTable stats={stats} />
      <Divider />
    </>
  );
};

export default LocationStatsOverview;
