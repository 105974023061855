// import { useHistory } from 'react-router-dom';
import base64url from 'base64url';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { createSession } from '@wonderschool/common-base-sso';

import { functions } from '../api/firebase';
import { sendResetPasswordEmail, userHasPermission } from '../api/firebase/account';
import { getEnv } from '../config/env';
import { usePaths } from '../navigation';

export const useAdminActions = () => {
  const history = useHistory();
  const { paths } = usePaths();

  const resetUserPassword = (uid: string, email: string) => {
    if (!uid || !email) throw new Error('Invalid user object');

    if (!userHasPermission('can_manage_users'))
      throw new Error('You do not have permission to send password reset link');

    return sendResetPasswordEmail(email);
  };

  const impersonateUser = async (uid: string) => {
    console.log('*** Impersonating user:', uid);
    try {
      const result = await functions().httpsCallable('callables-generateUserToken')({ userId: uid });

      const token = result.data.token;
      if (!token) throw new Error('Failed to generate user token');

      const authSessionId = uuidv4();
      await createSession(getEnv(), uid, authSessionId);

      const payload = {
        jwt: token,
        lang: 'en',
        rts: {},
        ext: {
          signin: true,
          lang: 'en',
          ses: authSessionId,
        },
      };

      const payloadString = base64url.encode(JSON.stringify(payload));
      const path = `${paths.authReturn}#${payloadString}`;
      history.push(path);
    } catch (error: any) {
      console.error('*** Failed to impersonate user: ', error);
      throw new Error(`Failed to impersonate user: ${error.message}`);
    }
  };
  return {
    impersonateUser,
    resetUserPassword,
  };
};

export default useAdminActions;
