import { Invoice, InvoiceDiscount, InvoiceItem, Payment } from '@wonderschool/common-base-types';
import { TFunction } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { invoiceV2StatusFormatter } from './invoiceStatusV2';
import { convertToDollars } from './money';
import { PAYOUT_STATUS, PayoutStatus, invoicePayoutStatusFormatter } from './payouts';
import { USER_STATUS, getFailurePopupText, userStatusFormatter } from './userStatus';

export type InvoiceV2 = any & {
  version?: string;
  itemsDescription?: string;
};

export const InvoiceStatus = {
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  UNCOLLECTIBLE: 'uncollectible',
  VOID: 'void',
  SCHEDULED: 'scheduled',
  FAILED: 'failed',
  PROCESSING: 'processing',
  REFUND_STARTED: 'refundStarted',
};
export const InvoiceItemCategory = {
  TUITION: 'tuition',
  CONVENIENCE_FEE: 'convenience fee',
};

export const INVOICE_UNSUCCESSFUL_STATUSES = [InvoiceStatus.FAILED, InvoiceStatus.VOID, InvoiceStatus.UNCOLLECTIBLE];

export const DiscountAmountType = {
  PERCENT: 'percent',
  CURRENCY: 'currency',
};

export const BILLING_FEE_TYPE = {
  ENROLLMENT: 'enrollment',
  REVENUE_SHARE: 'revenue_share',
  PROCESSING_FEE: 'processing_fee',
};

export type LegacyInvoiceStatus = {
  text: string;
  color: string;
  popupText: string;
  userStatus: USER_STATUS;
};

export type BillingFees = {
  feeType: string;
  amount: number;
};

/**
 * Handle legacy invoices that do not have the userStatus field.
 */
const legacyStatusFormatter = (invoice, t): LegacyInvoiceStatus | PayoutStatus => {
  const { paidAmount, status, total, payout, emailNotifications, failureMessage, nextPaymentAttempt, manuallyPaid } =
    invoice;

  const hasPayout = Boolean(payout?.status);
  const isInvoiceSuccessful = !isInvoiceUnsuccessful(invoice);

  if (hasPayout && isInvoiceSuccessful) {
    return invoicePayoutStatusFormatter(payout);
  }

  const hasSentEmailNotification = !!(emailNotifications && emailNotifications.length > 0);

  switch (status) {
    case hasSentEmailNotification && InvoiceStatus.OPEN: {
      return {
        text: 'Notification sent',
        color: 'blue',
        popupText: 'notificationInvoicePopup',
        userStatus: USER_STATUS.NOTIFICATION_SENT,
      };
    }
    case hasSentEmailNotification && InvoiceStatus.SCHEDULED: {
      return {
        text: 'Notification sent',
        color: 'blue',
        popupText: 'notificationInvoicePopup',
        userStatus: USER_STATUS.NOTIFICATION_SENT,
      };
    }
    case InvoiceStatus.DRAFT: {
      return { text: 'New', color: 'grey', popupText: 'newInvoicePopup', userStatus: USER_STATUS.DRAFT };
    }
    case InvoiceStatus.VOID: {
      return { text: 'Void', color: 'red', userStatus: USER_STATUS.VOID };
    }
    case InvoiceStatus.UNCOLLECTIBLE: {
      return {
        text: 'Uncollectible',
        color: 'pink',
        popupText: getFailurePopupText(t, failureMessage, nextPaymentAttempt),
        userStatus: USER_STATUS.UNCOLLECTIBLE,
      };
    }
    case InvoiceStatus.FAILED: {
      return {
        text: 'Failed',
        color: 'red',
        popupText: getFailurePopupText(t, failureMessage, nextPaymentAttempt),
        userStatus: USER_STATUS.CHARGE_FAILED,
      };
    }
    case InvoiceStatus.OPEN: {
      return {
        text: 'Invoice sent',
        color: 'blue',
        popupText: 'sentInvoicePopup',
        userStatus: USER_STATUS.INVOICE_SENT,
      };
    }
    case InvoiceStatus.SCHEDULED: {
      return {
        text: 'New',
        color: 'yellow',
        popupText: 'scheduledInvoicePopup',
        userStatus: USER_STATUS.SCHEDULED,
      };
    }
    case InvoiceStatus.PAID: {
      if (paidAmount > 0 && paidAmount < total) {
        return {
          text: 'Partially paid',
          color: 'orange',
          popupText: 'partiallyPaidInvoicePopup',
          userStatus: USER_STATUS.PAID,
        };
      }
      const popupText = manuallyPaid ? 'paidOfflineInvoicePopup' : 'paidOnlineInvoicePopup';
      return {
        text: manuallyPaid ? 'Paid(O)' : 'status.deposited',
        color: 'green',
        popupText,
        userStatus: manuallyPaid ? USER_STATUS.PAID_MANUALLY : USER_STATUS.PAID,
      };
    }
    default: {
      return {
        text: 'Invoice sent',
        color: 'blue',
        popupText: 'sentInvoicePopup',
        userStatus: USER_STATUS.INVOICE_SENT,
      };
    }
  }
};

/**
 * The invoice status formatter for both legacy and V2 invoices.
 *
 * @param invoice The invoice object
 * @param t The translation function
 * @returns The formatted invoice status
 */
export function invoiceStatusFormatter(invoice: Invoice | InvoiceV2, t: TFunction) {
  if (isV2Invoice(invoice)) {
    return invoiceV2StatusFormatter(invoice, t);
  } else {
    const { userStatus, userStatusDescription, failureMessage, nextPaymentAttempt, payments, refundFailedReason } =
      invoice;
    if (userStatus && !(invoice.status === InvoiceStatus.VOID || invoice.status === InvoiceStatus.UNCOLLECTIBLE)) {
      return userStatusFormatter(
        { userStatus, userStatusDescription, failureMessage, nextPaymentAttempt, payments, refundFailedReason },
        t
      );
    }

    return legacyStatusFormatter(invoice, t);
  }
}

/**
 * This function calculates the total amount of the invoice items.
 * It also takes into account the discounts applied to the invoice items.
 *
 * @param invoiceItems The invoice items
 * @returns The total amount of the invoice items
 */
export const calculateTotalFromInvoiceItems = (invoiceItems: InvoiceItem[]) => {
  let total = 0;
  invoiceItems.forEach((item) => {
    total += item.amount;
    item.discounts.forEach((discount) => {
      const { currencyAmount } = calculateDiscountAmounts(discount, item.amount);
      total -= currencyAmount;
    });
  });
  return total;
};

// The 'amount' property of a discount can either be an amount, or a percentage, depending
// on the value of the 'amountType' property.
// Return an object shaped like this:{currency, percent}, where we calculate either the
// amount or the percentage based on the value of the 'amountType' property.
export function calculateDiscountAmounts(discount, invoiceItemAmount) {
  const totalAmount = invoiceItemAmount ?? 0;
  if (isDiscountPercent(discount)) {
    if (!discount.amount) return { percentAmount: 0, currencyAmount: 0 };
    else
      return {
        percentAmount: discount.amount,
        currencyAmount: totalAmount * (discount.amount / 100),
      };
  } else {
    // it's currency
    if (!totalAmount) return { percentAmount: 0, currency: discount.amount };
    else
      return {
        percentAmount: (discount.amount / totalAmount) * 100,
        currencyAmount: discount.amount,
      };
  }
}
export function isDiscountPercent(discount: InvoiceDiscount) {
  return discount?.amountType === DiscountAmountType.PERCENT;
}
export function isDiscountCurrency(discount: InvoiceDiscount) {
  return !discount.amountType || discount.amountType === DiscountAmountType.CURRENCY;
}

export function isInvoiceDue(invoice: Invoice) {
  if (!invoice?.dateDue) return false;

  const now = moment().startOf('day');
  const dueDate = moment(invoice.dateDue).startOf('day');
  return dueDate.isBefore(now);
}

export function getPastDueInvoices(invoices: Invoice[]) {
  if (!invoices) return [];

  return invoices.filter((invoice) => isInvoiceDue(invoice));
}

export const isInvoiceUnsuccessful = (invoice: Invoice) => {
  return INVOICE_UNSUCCESSFUL_STATUSES.some((status) => status === invoice.status);
};

export const isInvoicePaid = (invoice: InvoiceV2) => {
  if (isInvoiceUnsuccessful(invoice)) return false;

  const { payout, manuallyPaid, status } = invoice;
  const hasPayoutStatus = !!payout?.status;
  if (hasPayoutStatus) return payout.status === PAYOUT_STATUS.PAID;
  if (manuallyPaid) return true;
  return !manuallyPaid && status === InvoiceStatus.PAID;
};

export const isInvoiceProcessing = (invoice) => {
  if (isInvoiceUnsuccessful(invoice)) return false;

  const { payout } = invoice;
  const hasPayoutStatus = !!payout?.status;
  return hasPayoutStatus && (payout.status === PAYOUT_STATUS.PENDING || payout.status === PAYOUT_STATUS.IN_TRANSIT);
};

export const getWSFeeListItemDescription = (listItem) => {
  switch (listItem.feeType) {
    case BILLING_FEE_TYPE.REVENUE_SHARE:
      return 'WSFeeListItemDescriptionRevenueShare';
    case BILLING_FEE_TYPE.PROCESSING_FEE:
      return 'WSFeeListItemDescriptionProcessingFee';
    default:
      return '{{descriptionValue}}';
  }
};

export const isInvoiceElegibleForArchive = (invoice) => {
  if (invoice.isArchived) return false;
  return isInvoicePaid(invoice) || [InvoiceStatus.VOID, InvoiceStatus.UNCOLLECTIBLE].includes(invoice.status);
};

export const isV2Invoice = (invoice: Invoice | InvoiceV2) => parseInt(invoice?.version || '1') > 1;

export const mapV2Invoice = (invoice) => {
  const {
    billedToEmail,
    billedToId,
    billedToName,
    dueAt,
    items,
    studentId,
    studentName,
    total,
    recurringPlanId,
    ...invoiceRest
  } = invoice;

  const billedTo = {
    displayName: billedToName,
    email: billedToEmail,
    id: billedToId,
  };

  const createdBy = {
    displayName: invoiceRest.createdByName,
  };

  return {
    ...invoiceRest,
    billedTo,
    createdBy,
    dateDue: dueAt,
    // Invoice list uses this field to determine if the invoice is from a recurring plan
    isInvoice: !recurringPlanId,
    invoicePlanId: recurringPlanId,
    student: {
      id: studentId,
      displayName: studentName,
    },
    total: convertToDollars(total), // Payments API stores money in cents
  };
};

export const mapV2InvoiceItems = (items: any[]) => {
  const discounts: InvoiceDiscount[] = [];
  const mappedItems: InvoiceItem[] = [];
  for (const item of items) {
    const { amount, amountType, category, description, relatesToId, type, ...itemRest } = item;

    if (category === 'discount') {
      const discountedAmount = amountType === DiscountAmountType.CURRENCY ? convertToDollars(amount) : amount; // Payments API stores money in cents
      discounts.push({
        amount: discountedAmount, // Payments API stores money in cents
        amountType,
        discountType: type,
        relatesToId,
      } as InvoiceDiscount);
    } else {
      mappedItems.push({
        ...itemRest,
        amount: convertToDollars(amount), // Payments API stores money in cents
        category,
        discounts: [],
        item: description,
      });
    }
  }

  for (const discount of discounts) {
    const { relatesToId, ...discountRest } = discount;
    const item = mappedItems.find((item) => item.id === relatesToId);
    item && item.discounts.push(discountRest);
  }

  return mappedItems;
};

export const mapV2InvoicePayments = (payments) => {
  return payments.map((payment) => {
    return {
      ...payment,
      paidAmount: payment.amount,
      version: '2.0.0', // Added because "renderPayments()" in InvoiceDetailContainer calls "renderInvoiceStatus()" and passes payments but that function expects invoices. We check for V2 invoices in that function.
    };
  });
};

export function getBillingFeeByType(billingFees: BillingFees[], feeType: string): BillingFees | null {
  return billingFees ? billingFees.find((fee) => fee.feeType === feeType) || null : null;
}

export function hasParentPaidInvoice(payments: Payment[]) {
  if (!payments) return false;
  return payments.some((payment) => payment.status === InvoiceStatus.PAID);
}

export function getProcessingFeeLineItem(invoiceItems: InvoiceItem[]) {
  if (!invoiceItems) return null;

  return (
    invoiceItems.find((payment) => payment.category?.toLowerCase() === InvoiceItemCategory.CONVENIENCE_FEE) || null
  );
}

export function getProcessingFeeAmount(invoice: any) {
  const hasInvoiceBeenPaid = hasParentPaidInvoice(invoice?.payments);
  const processingFeeLineItem = hasInvoiceBeenPaid ? getProcessingFeeLineItem(invoice?.invoiceItemList) : null;
  return processingFeeLineItem ? processingFeeLineItem?.amount : 0;
}
