import React, { useState, useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Card } from 'semantic-ui-react';
import { connect } from 'react-redux';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty } from 'lodash';

import { organizationStatsOnListen } from '../../api/firebase/stats';
import { useTimecards } from '../../timecards';
import { useContacts } from '../../contacts';

import CheckedInStats from './CheckedInStats';
import LocationStatsOverview from './LocationStatsOverview';
import withPermission from '../Shared/withPermission';

const LocationStatsWithPermission = withPermission(LocationStatsOverview, 'can_view_center_stats');
import FinanceAtGlanceOverview from '../Billing/FinanceAtGlance/FinanceAtGlanceOverview';
import { useUser } from '../../hooks/useUser';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CenterOverview = ({ rooms, students, currentOrganization: { id = null, programType = null } }) => {
  const { clockedInTimecards } = useTimecards();
  const { staffContacts } = useContacts();
  const { isLocationAdmin, isOrganizationAdmin } = useUser();

  const [stats, setStats] = useState();
  const [roomsWithStudents, setRoomsWithStudents] = useState({});
  const { isFinanceAtAGlanceEnabled } = useFlags();
  useEffect(() => {
    const unsubscribeOrganizations = organizationStatsOnListen(id, (stats) => {
      const allStats = {};
      stats.list.map((stat) => (allStats[stat.id] = stat));
      setStats(allStats);
    });

    const presentStudents = students.filter((student) => student.isPresent);

    const _roomsWithStudents = {};

    rooms.forEach((room) => {
      const studentsInRoom = presentStudents.filter((student) => student.rooms.includes(room.id));
      _roomsWithStudents[room.id] = studentsInRoom;
    });

    setRoomsWithStudents(_roomsWithStudents);

    return () => {
      unsubscribeOrganizations();
    };
  }, [id, students, rooms]);

  const roomsWithStaff = useMemo(() => {
    if (!staffContacts?.length || !clockedInTimecards?.length) return {};

    const clockedInStaff = staffContacts.filter((contact) => {
      return !!clockedInTimecards.find((timecard) => timecard.contactId === contact.id);
    });

    const roomsMap = rooms.reduce((acc, room) => {
      acc[room.id] = room;
      return acc;
    }, {});

    const staffMap = clockedInStaff.reduce((acc, contact) => {
      acc[contact.id] = contact;
      return acc;
    }, {});

    return clockedInTimecards.reduce((map, timecard) => {
      if (!roomsMap[timecard.roomId] || !staffMap[timecard.contactId]) return map;

      if (!map[timecard.roomId]) map[timecard.roomId] = [];
      map[timecard.roomId].push(staffMap[timecard.contactId]);

      return map;
    }, {});
  }, [rooms, staffContacts, clockedInTimecards]);

  if (isEmpty(stats)) return null;

  return (
    <Card fluid className="no-padding">
      <Card.Content>
        <LocationStatsWithPermission stats={stats} />
        <CheckedInStats
          rooms={rooms}
          stats={stats}
          programType={programType}
          roomsWithStudents={roomsWithStudents}
          roomsWithStaff={roomsWithStaff ?? {}}
        />
        {isFinanceAtAGlanceEnabled && (isLocationAdmin || isOrganizationAdmin) && (
          <div>
            <hr style={{ marginTop: '30px', marginBottom: '10px' }} /> <FinanceAtGlanceOverview />
          </div>
        )}
      </Card.Content>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  currentOrganization: state.organizations.currentOrganization,
  rooms: state.rooms.list,
  students: state.students.list,
});

export default connect(mapStateToProps)(CenterOverview);
