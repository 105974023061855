import { StaffPage } from '@wonderschool/ccms-ui';
import { RouteNameEnum, useRoutes } from '../navigation';

const Staff = () => {
  const { gotoRouteByName } = useRoutes();
  const handleStaffRowClick = (staffId: string) => {
    return gotoRouteByName(RouteNameEnum.STAFF_DETAILS, [{ name: 'staffId', value: staffId }]);
  };

  return <StaffPage onNavigateToStaffDetails={handleStaffRowClick} />;
};

export default Staff;
