import accounting, { CurrencyFormat, CurrencySettings } from 'accounting';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import i18n from 'i18next';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty, pull } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { StudentType as Student } from '@wonderschool/common-base-types';

import fetchTimezoneWithGoogleApi from '../api/fetchTimezoneWithGoogleApi';
import { timezoneOptions } from '../config';
import store from '../redux/store';

import { DiscountAmountType } from './invoices';

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * Returns the capitalized version of a string.
 *
 * @param value The string to capitalize
 * @returns The capitalized string
 *
 * @example
 * capitalize('james') => James
 * capitalize('not james') => Not james
 * capitalize('tOtallY nOt JaMeS') => TOtallY nOt JaMeS
 */
export function capitalize(value: string) {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * This function formats a phone number to E164 format.
 *
 * @param phoneNumber The phone number to be formatted
 * @param code The country code, defaults to 'US'
 * @returns The formatted phone number
 *
 * @example
 * phoneNumberFormat('+1 202-555-0100') => +12025550100
 * phoneNumberFormat('+1 202-555-0100', 'US') => +12025550100
 */
export function phoneNumberFormat(phoneNumber, code = 'US') {
  if (!phoneNumber) {
    return '';
  }
  return phoneUtil.format(phoneUtil.parseAndKeepRawInput(phoneNumber, code), PhoneNumberFormat.E164);
}

/**
 * This function parses a phone number to national format.
 *
 * @param phoneNumber The phone number to be parsed
 * @param code The country code, default is US
 * @returns The parsed phone number
 *
 * @example
 * phoneNumberParse('+1 202-555-0100') => (202) 555-0100
 * phoneNumberParse('+1 202-555-0100', 'US') => (202) 555-0100
 */
export function phoneNumberParse(phoneNumber, code = 'US') {
  if (!phoneNumber) {
    return '';
  }
  return phoneUtil.format(phoneUtil.parseAndKeepRawInput(phoneNumber, code), PhoneNumberFormat.NATIONAL);
}

/**
 * Converts bytes to human readable file size.
 *
 * @param bytes The bytes to be converted
 * @returns The human readable file size
 *
 * @example
 * humanReadableFileSize(1024) => 1 KB
 * humanReadableFileSize(1024 * 1024) => 1 MB
 * humanReadableFileSize(1024 * 1024 * 1024) => 1 GB
 * humanReadableFileSize(1024 * 1024 * 1024 * 1024) => 1 TB
 */
export function humanReadableFileSize(bytes: number) {
  if (bytes === 0) return '0 B';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
}

/**
 * Formats a currency value to a human readable format.
 *
 * @param value The value to be formatted.
 * @param options The options to be used for formatting.
 * @returns The formatted currency.
 *
 * @example
 * currencyFormatter(1000) => $1,000.00
 * currencyFormatter(1000, { symbol: '€' }) => €1,000.00
 * currencyFormatter(1000, { precision: 0 }) => $1,000
 * currencyFormatter(1000, { symbol: '€', precision: 0 }) => €1,000
 * currencyFormatter(1000, { symbol: '€', precision: 0, thousand: '.', decimal: ',' }) => €1.000
 */
export function currencyFormatter(
  value: number | string = 0,
  options?: CurrencySettings<string> | CurrencySettings<CurrencyFormat>
): string {
  const currencyOptions = {
    symbol: '$',
    decimal: '.',
    thousand: ',',
    precision: 2,
  };
  return accounting.formatMoney(value, { ...currencyOptions, ...options });
}

/**
 * TODO: Document your code!
 * @constructor
 * @param {string} invoices - The invoices.
 * @param {boolean} invoiceMode - The invoice.
 */
export const selectInvoices = (invoices, invoiceMode = true) =>
  Object.keys(invoices)
    .filter((key) => invoices[key].isInvoice === invoiceMode)
    .map((id) => {
      return {
        id: id,
        ...invoices[id],
      };
    });

/**
 * TODO: Document your code!
 * @constructor
 * @param {string} students - The students list.
 */

const totalShape = {
  first: 0,
  second: 0,
  third: 0,
  fourth: 0,
  fifth: 0,
  total: 0,
};

export function selectTotalByEmployee(students: Record<string, any>) {
  const result = {};
  const totalSummary = { ...totalShape };

  for (const studentId in students) {
    const studentInvoices = students[studentId];

    if (result[studentId] === undefined) {
      result[studentId] = { ...totalShape, name: null };
    }

    for (const currentInvoice in studentInvoices) {
      const targetDate = studentInvoices[currentInvoice]?.dateDue;
      if (!targetDate) continue;

      const difference = moment().diff(moment(targetDate), 'days');

      if (difference < 7) {
        result[studentId].first += studentInvoices[currentInvoice].total;
      } else if (difference >= 7 && difference < 14) {
        result[studentId].second += studentInvoices[currentInvoice].total;
      } else if (difference >= 14 && difference <= 20) {
        result[studentId].third += studentInvoices[currentInvoice].total;
      } else if (difference > 20 && difference <= 28) {
        result[studentId].fourth += studentInvoices[currentInvoice].total;
      } else if (difference > 28) {
        result[studentId].fifth += studentInvoices[currentInvoice].total;
      }

      result[studentId].total += studentInvoices[currentInvoice].total;

      if (!result[studentId].displayName) {
        result[studentId].displayName = studentInvoices[currentInvoice].student.displayName;
      }
    }

    totalSummary.first += result[studentId].first;
    totalSummary.second += result[studentId].second;
    totalSummary.third += result[studentId].third;
    totalSummary.fourth += result[studentId].fourth;
    totalSummary.fifth += result[studentId].fifth;
    totalSummary.total += result[studentId].total;
  }

  return {
    totalByEmployee: Object.keys(result).map((key) => {
      return {
        id: key,
        ...result[key],
      };
    }),
    totalSummary,
  };
}

const defaultCurrencyOptions = {
  symbol: '$',
  decimal: '.',
  thousand: ',',
  precision: 2,
};

export const formatCurrency = (amount, options = {}) => {
  let formatted = '$0.00';
  const currencyOptions = { ...defaultCurrencyOptions, ...options };

  if (amount) {
    // accounting pkg formnats negative numbers like $ -XX.XX so we have to override
    if (Math.sign(amount) === -1) {
      formatted = `-${currencyOptions.symbol}${accounting.formatNumber(Math.abs(amount), options)}`;
    } else {
      formatted = accounting.formatMoney(amount, currencyOptions);
    }
  }
  return formatted;
};

/**
 * This function parses a currency value into a number.
 * @param val The value to be parsed, e.g. $1,000.00 or 1000.00
 * @returns The parsed currency value, e.g. 1000.00
 */
export const parseCurrency = (val: string) => {
  return accounting.unformat(val);
};

export const formatPercent = (amount, precision = 1) => {
  if (!amount) return '0%';

  const intAmount = Math.floor(amount);
  if (amount === intAmount) return `${intAmount}%`;
  else return amount.toFixed(precision) + '%';
};

export const getFormatter = (amountType) => {
  if (amountType === DiscountAmountType.PERCENT) return formatPercent;
  return formatCurrency;
};

export const centsToDollars = (amount) => {
  return amount / 100;
};

/**
 * This function toggles array. If the value exists in an array
 * it removes it, if not, it adds it.
 * @constructor
 * @param {array} arr - An list.
 * @param {string} val - Value that needs to be checked.
 */

export const toggleArray = (arr, val) => {
  if (arr.length === pull(arr, val).length) {
    arr.push(val);
  }

  return arr;
};

/**
 * This function combines name fields into a single string. It assumes but doesn't require that the
 * object have some combination of firstName, middleName, and lastName.
 *
 * If all fields are empty, it'll return an empty string and warn in the console.
 *
 * @param {Object} person - person-like object
 * @param {string=} [person.firstName=''] - Person's first name
 * @param {string=} [person.middleName=''] - Person's middle name
 * @param {string=} [person.lastName=''] - Person's last name
 * @param {boolean=} [skipMiddle=false] - Ignore middleName
 */
export const formatFullName = (person, skipMiddle = false) => {
  const { firstName = '', middleName = '', lastName = '' } = person;
  const startingArr = skipMiddle ? [firstName, lastName] : [firstName, middleName, lastName];

  const nonEmpty = startingArr.filter((x) => x.length > 0);

  if (nonEmpty.length === 0) {
    //  console.warn('Tried to extract empty name from person: ', person);
    return '';
  }

  return nonEmpty.join(' ');
};

export function getDisplayNameOrFormatFullName(person, skipMiddle = false) {
  return person.displayName || formatFullName(person, skipMiddle);
}
/**
 * Proper case function.
 *
 * @param {string} str - The string to convert.
 */
export const toProperCase = (str) => {
  if (isEmpty(str)) return '';

  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isObject = function (objectMaybe) {
  return objectMaybe === Object(objectMaybe) && !Array.isArray(objectMaybe) && typeof objectMaybe !== 'function';
};

export const toCamelCase = (inputString) => {
  return inputString.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const objectKeysToCamelCase = (object) => {
  if (isObject(object)) {
    const newObject = {};

    Object.keys(object).forEach((key) => {
      newObject[toCamelCase(key)] = objectKeysToCamelCase(object[key]);
    });

    return newObject;
  } else if (Array.isArray(object)) {
    return object.map((key) => objectKeysToCamelCase(key));
  }

  return object;
};

export const formatNumberShorthand = (n) => {
  // use built in js library to calculate the short hand number format
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 2,
  }).format(n);
};

export const formatLocationPlaceObject = async (place) => {
  let stNumber;
  let stName;
  let city;
  let state;
  let zipcode;

  place.address_components.forEach((address_comp) => {
    address_comp.types.forEach((type) => {
      if (type === 'street_number') {
        stNumber = address_comp.long_name;
      }

      if (type === 'route') {
        stName = address_comp.long_name;
      }

      if (type === 'locality') {
        city = address_comp.long_name;
      }

      if (type === 'administrative_area_level_1') {
        state = address_comp.short_name;
      }

      if (type === 'postal_code') {
        zipcode = address_comp.long_name;
      }
    });
  });

  const googleTzApiResponse = await fetchTimezoneWithGoogleApi({
    lat: place.geometry.location.lat(),
    long: place.geometry.location.lng(),
  });

  const reshaped = {
    formatted: {
      address1: `${stNumber} ${stName}`,
      city,
      state,
      zipcode,
      timezone: timezoneOptions.find((tz) => tz.value === googleTzApiResponse?.timeZoneId) || null,
      geometry: place.geometry,
    },
    raw: place,
  };

  return reshaped;
};

export const CLAIMS_ENUM = {
  moxitAdmin: 'moxitAdmin',
  organizationAdmin: 'organizationAdmin',
  locationAdmin: 'locationAdmin',
  teacher: 'teacher',
  supportStaff: 'supportStaff',
};
export const testHighestClaim = (userClaims, targetClaim) => {
  if (!userClaims) {
    return false;
  }
  if (targetClaim === CLAIMS_ENUM.moxitAdmin) if (userClaims.moxitAdmin) return true;

  if (targetClaim === CLAIMS_ENUM.organizationAdmin)
    if (!userClaims.moxitAdmin && userClaims.organizationAdmin) return true;

  if (targetClaim === CLAIMS_ENUM.locationAdmin)
    if (!userClaims.moxitAdmin && !userClaims.organizationAdmin && userClaims.locationAdmin) return true;

  if (targetClaim === CLAIMS_ENUM.teacher)
    if (!userClaims.moxitAdmin && !userClaims.organizationAdmin && !userClaims.locationAdmin)
      if (userClaims.teacher) return true;

  if (targetClaim === CLAIMS_ENUM.supportStaff && userClaims.supportStaff)
    if (!userClaims.moxitAdmin && !userClaims.organizationAdmin && !userClaims.locationAdmin && !userClaims.teacher)
      return true;

  return false;
};

export const isUsersHighestClaimOneOf = (userClaims, targetClaims) => {
  for (const claim of targetClaims) {
    if (testHighestClaim(userClaims, claim)) {
      return true;
    }
  }
  return false;
};

export const getUsersRoomsOrLocations = (desiredUserPropKey) => {
  const userStore = store.getState()?.user || [];
  const { claims } = userStore;
  const targetClaims = [CLAIMS_ENUM.locationAdmin, CLAIMS_ENUM.teacher, CLAIMS_ENUM.supportStaff];

  if (isUsersHighestClaimOneOf(claims, targetClaims)) {
    const results = Object.keys(userStore[desiredUserPropKey]);
    return results.length > 0 ? results : ['-1']; // a fake room id to make sure we don't elevate permissions for users without rooms or locations
  }

  return [];
};

export const deriveLanguage = () => {
  const language = i18n?.resolvedLanguage ?? i18n?.language ?? 'en';
  if (language === 'es') return 'es';
  else return 'en';
};

export function convertListToCSV(data, columns, t) {
  if (data.length === 0) {
    return '';
  }
  const headers = Object.keys(data[0]);
  // Create header row
  const headerRow = columns.map((item) => t(item)).join(', ') + '\n';

  // Create data rows, including only values for filtered columns
  const dataRows = data.map(
    (item) =>
      headers.map((header) => (item[header] !== undefined ? escapeCSVField(t(String(item[header]))) : '')).join(',') +
      '\n'
  );

  // Combine header and data rows
  const csv = headerRow + dataRows.join('');
  return csv;
}

// Helper function to escape CSV field with quotes if it contains commas
export function escapeCSVField(field) {
  return field?.includes(',') ? `"${field}"` : field;
}

export const getMemberByIndex = (student: Student, index: number) => {
  const memberKey = Object.keys(student.family)[index];
  return student.family[memberKey];
};
