import { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import { Button, Grid, Header, Icon, Modal, Popup } from 'semantic-ui-react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { LoadingIndicator } from '../Shared/BusyIndicator';

import { formatStringAsLocaleMillisOrNull, DefaultDateFormat } from '../../helpers/dates';
import { fetchActivitiesForOrganization } from '../../api/firebase/activities';

import styles from './AttendanceList.module.scss';
import { Link } from 'react-router-dom';
import SignatureModal from './SignatureModal';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import PageTitle from '../Shared/PageTitle';
import HealthCheckModal from './HealthCheckModal';
import wsLogo from '../../styles/img/wonderschool/logo-base.png';
import { storage } from '../../api/firebase';
import { logError } from '../../rollbar';
import { useOrganization } from '../../hooks/useOrganizations';
import useRooms from '../../hooks/useRooms';
import { useLocations } from '../../hooks/useLocations';

const AttendanceList = ({ filters, loading, setLoading }) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const rooms = useRooms();
  const locations = useLocations();

  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState(null);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [shouldOpenPdfModal, setShouldOpenPdfModal] = useState(false);

  const selectedLocation = locations.list.find((location) => location.id === filters.location);

  useEffect(() => {
    const _getAttendanceReports = async () => {
      try {
        const conditions = [
          {
            field: 'type',
            operation: 'in',
            value: ['dropoff', 'pickup', 'absent'],
          },
          {
            field: 'activityTime',
            operation: '>=',
            value: formatStringAsLocaleMillisOrNull(moment(filters.timePeriod.from).startOf('day')),
          },
          {
            field: 'activityTime',
            operation: '<=',
            value: moment(filters.timePeriod.to, DefaultDateFormat).isSame(moment(new Date(), DefaultDateFormat))
              ? formatStringAsLocaleMillisOrNull(moment(new Date()))
              : formatStringAsLocaleMillisOrNull(moment(filters.timePeriod.to).endOf('day')),
          },
        ];

        if (filters.location) {
          conditions.push({
            field: 'location',
            operation: '==',
            value: filters.location,
          });
        }

        if (filters.room) {
          conditions.push({
            field: 'room',
            operation: '==',
            value: filters.room,
          });
        }

        if (filters.student) {
          conditions.push({
            field: 'student.id',
            operation: '==',
            value: filters.student,
          });
        }
        setLoading(true);
        const data = await fetchActivitiesForOrganization({
          organizationId: organization.id,
          conditions,
          orderBy: [
            { field: 'activityTime', direction: 'desc' },
            { field: 'addedBy.createdAt', direction: 'desc' },
          ],
        });
        setActivities(data);
      } catch (err) {
        logError('Error while getting attendance report data: ', err);
      } finally {
        setLoading(false);
      }
    };
    _getAttendanceReports();
  }, [filters, organization, setLoading]);

  const handleSelectActivity = (activity) => {
    setActivity(activity);
  };

  const getTableColumns = useCallback(() => {
    const tableColumns = [
      {
        dataField: 'student.fullName',
        text: t('Student'),
        sort: true,
        formatter: (cell) => cell,
        csvFormatter: (cell) => cell,
      },
      {
        dataField: 'room',
        text: t('Room'),
        sort: true,
        formatter: (cell) => rooms.find((room) => room.id === cell)?.name,
        csvFormatter: (cell) => rooms.find((room) => room.id === cell)?.name,
      },
      {
        dataField: 'checkIn',
        text: t('Date'),
        sort: true,
        headerStyle: () => {
          return { width: '130px' };
        },
        sortFunc: (a, b, order) => {
          if (order === 'asc') {
            return b.activityTime - a.activityTime;
          }
          return a.activityTime - b.activityTime; // desc
        },
        formatter: (cell) => {
          if (!cell?.activityTime) return '-';

          if (cell?.timezone) {
            return moment.tz(cell.activityTime, cell.timezone).format('MM/DD/YYYY');
          } else {
            return moment(cell.activityTime).format('MM/DD/YYYY');
          }
        },
        csvFormatter: (cell) => {
          if (!cell?.activityTime) return '-';

          if (cell?.timezone) {
            return moment.tz(cell.activityTime, cell.timezone).format('MM/DD/YYYY');
          } else {
            return moment(cell.activityTime).format('MM/DD/YYYY');
          }
        },
      },
      {
        dataField: 'checkIn.activityTime',
        text: t('Check In'),
        sort: true,
        headerStyle: () => {
          return { width: '180px' };
        },
        formatter: (cell, row) => {
          if (!cell) return '-';

          if (row.checkIn?.type === 'absent') {
            return (
              <>
                <div className="check-in-out-bg">
                  <Icon name="dont" className="absent" />
                  {'Absent'}
                </div>
              </>
            );
          } else {
            if (row.checkIn?.timezone) {
              return (
                <>
                  <div className="check-in-out-bg">
                    <Icon name="sign-out" className="check-in" />
                    {`${moment.tz(cell, row.checkIn.timezone).format('h:mma')} `}
                    {row?.checkIn?.preCheck && (
                      <Popup
                        trigger={
                          <Link
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSelectActivity(row);
                            }}
                          >
                            <Icon circular size="small" className="health-check" name="heading" />
                          </Link>
                        }
                        content={
                          <>
                            <b>{t('Health Check preformed.')}</b>
                            <br />
                            {t('Click for more information.')}
                          </>
                        }
                      />
                    )}
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div className="check-in-out-bg">
                    <Icon name="sign-out" className="check-in" />
                    {`${moment(cell).format('h:mma')} `}
                    {row?.checkIn?.preCheck && (
                      <Popup
                        content="test"
                        trigger={
                          <Link
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleSelectActivity(row);
                            }}
                          >
                            <Icon circular size="small" className="health-check" name="heading" />
                          </Link>
                        }
                      />
                    )}
                  </div>
                </>
              );
            }
          }
        },
        csvFormatter: (cell, row) => {
          if (!cell) return '-';
          if (row.checkIn?.type === 'absent') {
            return 'Absent';
          } else {
            if (row.checkIn?.timezone) {
              return moment.tz(cell, row.checkIn.timezone).format('h:mma');
            } else {
              return moment(cell).format('h:mma');
            }
          }
        },
      },
      {
        dataField: 'checkIn.addedBy',
        text: t('Signee'),
        formatter: (cell, row) =>
          cell?.fullName ? <SignatureModal row={row} type="dropoff" textToDisplay={cell?.fullName} /> : '-',
        csvFormatter: (cell) => cell?.fullName ?? '-',
      },
      {
        dataField: 'checkOut',
        text: t('Date'),
        sort: true,
        headerStyle: () => {
          return { width: '130px' };
        },
        sortFunc: (a, b, order) => {
          if (order === 'asc') {
            return b.activityTime - a.activityTime;
          }
          return a.activityTime - b.activityTime; // desc
        },
        formatter: (cell) => {
          if (!cell?.activityTime) return '-';

          if (cell?.timezone) {
            return moment.tz(cell.activityTime, cell.timezone).format('MM/DD/YYYY');
          } else {
            return moment(cell.activityTime).format('MM/DD/YYYY');
          }
        },
        csvFormatter: (cell) => {
          if (!cell?.activityTime) return '-';

          if (cell?.timezone) {
            return moment.tz(cell.activityTime, cell.timezone).format('MM/DD/YYYY');
          } else {
            return moment(cell.activityTime).format('MM/DD/YYYY');
          }
        },
      },
      {
        dataField: 'checkOut.activityTime',
        text: t('Check Out'),
        sort: true,
        headerStyle: () => {
          return { width: '150px' };
        },
        formatter: (cell, row) => {
          if (!cell) return '-';

          if (row.checkOut?.timezone) {
            return (
              <>
                <div className="check-in-out-bg">
                  <Icon name="sign-out" className="check-in" flipped="horizontally" />
                  {moment.tz(cell, row.checkOut.timezone).format('h:mma')}
                </div>
              </>
            );
          } else {
            return (
              <>
                <div className="check-in-out-bg">
                  <Icon name="sign-out" className="check-in" flipped="horizontally" />
                  {moment(cell).format('h:mma')}
                </div>
              </>
            );
          }
        },
        csvFormatter: (cell, row) => {
          if (!cell) return '-';
          if (row.checkOut?.timezone) {
            return moment.tz(cell, row.checkOut.timezone).format('h:mma');
          } else {
            return moment(cell).format('h:mma');
          }
        },
      },
      {
        dataField: 'checkOut.addedBy',
        text: t('Signee'),
        formatter: (cell, row) =>
          cell?.fullName ? <SignatureModal row={row} type="pickup" textToDisplay={cell?.fullName} /> : '-',
        csvFormatter: (cell) => cell?.fullName ?? '-',
      },
    ];

    return tableColumns;
  }, [rooms, t]);

  async function addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.setAttribute('crossorigin', 'anonymous');
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }

  const getSignatureUrl = async (signatureUri) => {
    try {
      if (signatureUri) {
        let pngUrl = signatureUri.replace('/svg/', '/png/');
        pngUrl = pngUrl.replace('.svg', '_200x200.png');
        const url = await storage().ref(pngUrl).getDownloadURL();
        const image = await addImageProcess(url);
        return image;
      } else {
        return '-';
      }
    } catch (err) {
      logError('Image not found: ', signatureUri, err);
      setPdfLoading(false);
      return '-';
    }
  };

  const formatData = async (datas) => {
    const formattedData = [];

    for (const data of datas) {
      const doc = {
        studentName: data.student.fullName,
        roomName: rooms.find((room) => room.id === data.room)?.name ?? '-',
        checkInDate: data?.checkIn?.activityTime
          ? data?.checkIn?.timezone
            ? moment.tz(data.checkIn.activityTime, data.checkIn.timezone).format('MM/DD/YYYY')
            : moment(data.checkIn.activityTime).format('MM/DD/YYYY')
          : '-',
        checkInTime: data?.checkIn?.activityTime
          ? data?.checkIn?.timezone
            ? moment.tz(data.checkIn.activityTime, data.checkIn.timezone).format('h:mma')
            : moment(data.checkIn.activityTime).format('h:mma')
          : '-',
        checkInSignne: data?.checkIn?.addedBy?.fullName ?? '-',
        checkInSignature: data?.checkIn?.signatureUri ? await getSignatureUrl(data.checkIn.signatureUri) : '-',
        checkOutDate: data?.checkOut?.activityTime
          ? data?.checkOut?.timezone
            ? moment.tz(data.checkOut.activityTime, data.checkOut.timezone).format('MM/DD/YYYY')
            : moment(data.checkOut.activityTime).format('MM/DD/YYYY')
          : '-',
        checkOutTime: data?.checkOut?.activityTime
          ? data?.checkOut?.timezone
            ? moment.tz(data.checkOut.activityTime, data.checkOut.timezone).format('h:mma')
            : moment(data.checkOut.activityTime).format('h:mma')
          : '-',
        checkOutSignne: data?.checkOut?.addedBy?.fullName ?? '-',
        checkOutSignature: data?.checkOut?.signatureUri ? await getSignatureUrl(data.checkOut.signatureUri) : '-',
        healthSymptoms: data?.checkIn?.preCheck
          ? new URL(data.checkIn.preCheck).searchParams.getAll('symptoms').join(', ')
          : '-',
        healthNotes: data?.checkIn?.preCheck
          ? new URL(data.checkIn.preCheck).searchParams.getAll('notes').join(', ')
          : '-',
      };
      formattedData.push(doc);

      if (data?.checkIn?.preCheck) {
        const healthSymptoms = new URL(data.checkIn.preCheck).searchParams.getAll('symptoms').join(', ') ?? '-';
        const healthNotes = new URL(data.checkIn.preCheck).searchParams.getAll('notes').join(', ') ?? '-';
        const checkInHealth = `${healthSymptoms}

${healthNotes}`;
        const doc1 = [
          {
            content: '',
            colSpan: 3,
          },
          {
            content: 'Health Check:',
            colSpan: 3,
            styles: { fontStyle: 'bold' },
          },
          // This block of code will be used in future with checkout notes
          /* {
            content: 'Notes:',
            colSpan: 3,
            styles: { fontStyle: 'bold' },
          }, */
        ];
        formattedData.push(doc1);
        const doc2 = [
          {
            content: '',
            colSpan: 3,
          },
          {
            content: checkInHealth,
            colSpan: 3,
          },
          // This block of code will be used in future with checkout notes
          /* {
            content: healthNotes,
            colSpan: 3,
          }, */
        ];
        formattedData.push(doc2);
      }
    }
    return formattedData;
  };

  const exportPdf = async () => {
    setPdfLoading(true);
    setShouldOpenPdfModal(true);
    const FILENAME = `Attendance ${organization.name} - ${selectedLocation.name} (${filters.timePeriod.from.format(
      'MM/DD/YYYY'
    )} - ${filters.timePeriod.to.format('MM/DD/YYYY')}).pdf`;
    const TITLE = `Check in/Check out Report for ${selectedLocation.name}`;
    const doc = new jsPDF();

    const COLUMN_HEADERS = [
      { header: 'Student Name', dataKey: 'studentName' },
      { header: 'Room', dataKey: 'roomName' },
      { header: 'Date', dataKey: 'checkInDate' },
      { header: 'Check in', dataKey: 'checkInTime' },
      { header: 'Signee', dataKey: 'checkInSignne' },
      { header: 'Signature', dataKey: 'checkInSignature' },
      { header: 'Date', dataKey: 'checkOutDate' },
      { header: 'Check out', dataKey: 'checkOutTime' },
      { header: 'Signee', dataKey: 'checkOutSignne' },
      { header: 'Signature', dataKey: 'checkOutSignature' },
    ];

    const BODY_DATA = await formatData(activities);

    doc.addImage(wsLogo, 'PNG', 10, 20, 40, 5);
    doc.setFontSize(14);
    doc.setFont(undefined, 'normal', 'bold');
    doc.text(TITLE, 10, 36);

    const ADDRESS = `${selectedLocation.address1}
${selectedLocation.city}, ${selectedLocation.state}, ${selectedLocation.zipcode}`;
    doc.setFont(undefined, 'normal', 'normal');
    doc.setFontSize(11);
    doc.text(ADDRESS, 10, 45);

    const TIME_PERIOD = `${filters.timePeriod.from.format('MM/DD/YYYY')} - ${filters.timePeriod.to.format(
      'MM/DD/YYYY'
    )}`;
    doc.setFont(undefined, 'normal', 'bold');
    doc.text('Time Period:', 10, 57);
    doc.setFont(undefined, 'normal', 'normal');
    doc.text(TIME_PERIOD, 40, 57);

    const ROOM_NAME = rooms.find((room) => room.id === filters.room)?.name;
    if (ROOM_NAME) {
      doc.setFont(undefined, 'normal', 'bold');
      doc.text('Room:', 90, 57);
      doc.setFont(undefined, 'normal', 'normal');
      doc.text(ROOM_NAME, 104, 57);
    }

    let prevColor = [248, 248, 248];
    let prevIndex = 0;

    // Or use javascript directly:
    autoTable(doc, {
      columns: COLUMN_HEADERS,
      body: BODY_DATA,
      startY: 65,
      margin: { left: 6 },
      columnStyles: {
        studentName: { cellWidth: 18 },
        roomName: { cellWidth: 20 },
        checkInDate: { cellWidth: 22 },
        checkInTime: { cellWidth: 18 },
        checkInSignne: { cellWidth: 20 },
        checkInSignature: { cellWidth: 20 },
        checkOutDate: { cellWidth: 22 },
        checkOutTime: { cellWidth: 18 },
        checkOutSignne: { cellWidth: 20 },
        checkOutSignature: { cellWidth: 20 },
      },
      didParseCell: (data) => {
        if (data.section === 'body') {
          if (prevIndex == data.row.index) {
            data.cell.styles.fillColor = prevColor;
          } else {
            prevIndex = data.row.index;
            if (data.row.raw['studentName']) {
              prevColor = prevColor === 255 ? [248, 248, 248] : 255;
            }
            data.cell.styles.fillColor = prevColor;
          }
        }
      },
      didDrawCell: (data) => {
        if (data.section === 'body' && data.cell.raw && data.cell.raw !== '-') {
          if (data?.column?.dataKey === 'checkOutSignature' || data?.column?.dataKey === 'checkInSignature') {
            doc.addImage(data.cell.raw, 'PNG', data.cell.x, data.cell.y, data.cell.width, data.cell.height);
          }
        }
      },
    });

    setPdfLoading(false);
    doc.save(FILENAME);
  };

  return (
    <>
      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={activities}
        columns={getTableColumns()}
        exportCSV={{
          fileName: `Attendance ${organization.name} - ${selectedLocation.name} (${filters.timePeriod.from.format(
            'MM/DD/YYYY'
          )} - ${filters.timePeriod.to.format('MM/DD/YYYY')}).csv`,
          ignoreHeader: false,
        }}
      >
        {({ baseProps, csvProps: _csvProps }) => (
          <Grid stackable>
            <Grid.Row>
              <Grid.Column computer={16} mobile={16} tablet={16}>
                <PageTitle
                  title={t('')}
                  RightComponent={() => (
                    <>
                      <Button
                        onClick={exportPdf}
                        secondary
                        floated="left"
                        disabled={activities.length === 0 || pdfLoading || loading}
                        data-testid="att-export-pdf"
                      >
                        {pdfLoading ? t('Loading...') : t('Print PDF')}
                      </Button>
                      <Button
                        onClick={() => _csvProps.onExport()}
                        primary
                        floated="right"
                        disabled={activities.length === 0 || pdfLoading || loading}
                        data-testid="att-export-csv"
                      >
                        {loading ? t('Loading...') : t('Export to CSV')}
                      </Button>
                    </>
                  )}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="bootstrap-iso">
                  {loading && <LoadingIndicator />}
                  <BootstrapTable
                    noDataIndication={() => (
                      <div className={styles.attendanceTableEmpty} data-testid="att-no-results">
                        {t('No results')}
                      </div>
                    )}
                    classes="w-auto w-md-100 text-gray-800"
                    wrapperClasses="table-responsive"
                    bordered={false}
                    hover={true}
                    rowEvents={{
                      onClick: (_, row) => {
                        handleSelectActivity(row);
                      },
                    }}
                    headerClasses={styles.attendanceTableHeader}
                    pagination={paginationFactory()}
                    {...baseProps}
                    data-testid="no-results"
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </ToolkitProvider>

      <HealthCheckModal
        organization={organization}
        activity={activity}
        onClose={() => setActivity(null)}
        open={!!activity}
      />

      <Modal
        onClose={() => setShouldOpenPdfModal(false)}
        open={shouldOpenPdfModal}
        closeIcon
        size="small"
        closeOnDimmerClick={false}
        data-testid="att-pdf-modal"
      >
        <Modal.Content className="pdf-message-modal" data-testid="att-pdf-message">
          <Header size="large">{t('Print PDF')}</Header>
          <p>{t('We are generating a report. This might take a few seconds. Please wait...')}</p>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default AttendanceList;
