import { useTranslation } from 'react-i18next';
import { ViewComponentProps } from '../../../common';
import { phoneNumberParse } from '../../../helpers/utils';
import { InvitationRecipientType } from '../../types';
import { SummaryViewTitle } from '../widgets';

type InvitationRecipientsViewProps = ViewComponentProps & {
  invitationRecipients: InvitationRecipientType[];
};

const InvitationRecipientsView: React.FC<InvitationRecipientsViewProps> = ({
  title,
  invitationRecipients,
  hasTitle = true,
}) => {
  const { t } = useTranslation();
  const viewTitle = hasTitle ? t(title || 'enrollments.invitationRecipientsViewTitle') : '';

  return (
    <div className="invitation-recipients-view" data-testid="ir-view">
      <SummaryViewTitle title={viewTitle} />
      {invitationRecipients.map((invitationRecipient) => (
        <div key={invitationRecipient.contactId} className="invitation-recipient ws-view-form">
          <h3>{invitationRecipient.displayName}</h3>
          <div className="row">
            <label>{t('common.email')}</label>
            <div className="value">{invitationRecipient.email}</div>
          </div>
          <div className="row">
            <label>{t('Phone')}</label>
            <div className="value">{phoneNumberParse(invitationRecipient.phone) || t('N/A')}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default InvitationRecipientsView;
