import { useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Button, Form, Header, Icon, Segment, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { logError } from '../../../rollbar';

import { sendMail } from '../../../api/firebase/mail';

import { useOrganization } from '../../../hooks/useOrganizations';
import { usePaths, useRoutes, RouteNameEnum } from '../../../navigation';
import { useStudent } from '../../../students/studentsHooks';
import { useDefaultBusinessHours } from '../../enrollmentsHooks';
import { useContacts } from '../../../contacts/contactsHooks';

import { DocumentType, InvitationRecipientType, ScheduledDayType, TuitionAndFeesType } from '../../types';
import { getDocuments, getInvitationRecipients, getScheduledDays, getTuitionAndFees } from '../../enrollmentsUtils';

import PageHeader from '../../../Components/Shared/PageHeader';
import LoadingComponent from '../../../Components/Shared/LoadingComponent';

import {
  DocumentsView,
  EnrollmentInfoView,
  InvitationMessageView,
  InvitationRecipientsView,
  ScheduleView,
  StudentView,
} from '../forms';
import { EnrollmentSentModal } from '../modals';
import { CONTACT_STATUS, INVITATION_TYPES } from '../../../contacts';
import { inviteContact } from '../../../contacts/contactsAPI';

type EnrollmentsPageProps = {
  onClick?: () => void;
};

const EnrollmentPage: React.FC<EnrollmentsPageProps> = () => {
  const { t } = useTranslation();
  const params: any = useParams();
  const organization = useOrganization();

  const defaultBusinessHours = useDefaultBusinessHours();
  const student = useStudent(params.studentId);
  const { goBack, gotoRouteByName } = useRoutes();
  const { paths } = usePaths();

  const { familyContacts, directorContact, contacts: _contacts } = useContacts();

  const invitationRecipients: InvitationRecipientType[] = useMemo(() => getInvitationRecipients(student), [student]);
  const documents: DocumentType[] = useMemo(() => getDocuments(student), [student]);
  const tuitionAndFees: TuitionAndFeesType = useMemo(() => getTuitionAndFees(student), [student]);
  const scheduledDays: ScheduledDayType[] = useMemo(
    () => getScheduledDays(student, defaultBusinessHours),
    [student, defaultBusinessHours]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [extraNotes, setExtraNotes] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [_isSending, _setIsSending] = useState(false);
  const [_errors, setErrors] = useState<string[]>([]);

  const sendEnrollmentInvitation = useCallback(
    async (contact: any) => {
      try {
        contact.invitationType = INVITATION_TYPES.enrollment;
        contact.invitationNotes = extraNotes;
        await inviteContact(contact);
      } catch (error: any) {
        logError('Error saving contact', contact, error);
        setErrors((prev) => [...prev, error.message]);
      }
    },
    [extraNotes]
  );

  const sendLoginEmail = useCallback(
    async (contact: any) => {
      if (!contact?.email) return;

      try {
        const mailData = {
          to: contact.email,
          template: {
            name: 'enrollment-parent-share-docs',
            data: {
              subject: "Please login to view your child's documents",
              parentName: contact.firstName,
              directorName: directorContact?.displayName,
              organizationName: organization.name,
              documentsLink: `${window.location.origin}${paths.documents}`, // paths.documents starts with a `/`
              notes: extraNotes || '-',
            },
          },
        };
        return sendMail(mailData);
      } catch (error: any) {
        logError('Error saving contact', contact, error);
        setErrors((prev) => [...prev, error.message]);
      }
    },
    [directorContact?.displayName, extraNotes, organization.name, paths.documents]
  );

  // iterate throught the recipients and set state in the contact to resend invitations, but using the
  // enrollment email template. If the contact is verified, instead of sending an invitation, we will
  // send an email to login and view their documents
  const sendEnrollmentInvitations = useCallback(async () => {
    for (const recipient of invitationRecipients) {
      const contact = familyContacts.find((c: any) => c.id === recipient.contactId);
      // If the contact is new or invited, we can re-send the invitation, but with an invitation type of enrollment
      // and with the extra notes
      if (contact?.status === CONTACT_STATUS.new || contact?.status === CONTACT_STATUS.invited) {
        await sendEnrollmentInvitation(contact);
      } else if (contact?.status === CONTACT_STATUS.verified) {
        await sendLoginEmail(contact);
      }
      setIsModalOpen(true);
    }
  }, [familyContacts, invitationRecipients, sendEnrollmentInvitation, sendLoginEmail]);

  if (!student) return <LoadingComponent />;

  return (
    <>
      <Segment loading={isSaving} basic clearing>
        <PageHeader pageName={'Enrollment'} classes="enrollments-page" />
        <Header as="h1" data-testid="dig-enroll-invitation">
          {t('enrollments.pageTitleSummary')}
        </Header>
        <Segment>
          <StudentView student={student} />
        </Segment>
        <Segment>
          <InvitationRecipientsView invitationRecipients={invitationRecipients} />
        </Segment>
        <Segment>
          <EnrollmentInfoView tuitionAndFees={tuitionAndFees} student={student} />
        </Segment>
        <Segment>
          <DocumentsView documents={documents} />
        </Segment>
        <Segment>
          <ScheduleView scheduledDays={scheduledDays} />
        </Segment>
        <Segment>
          <InvitationMessageView />
          {renderExtraNotesForm()}
        </Segment>
        <CommandButtons />
      </Segment>
      <EnrollmentSentModal student={student} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );

  function CommandButtons() {
    return (
      <div className="ws-form-buttons" data-testid="es-ws-form-buttons">
        <Button
          primary
          basic
          loading={isSaving}
          onClick={() => {
            gotoRouteByName(RouteNameEnum.HOME);
          }}
          data-testid="es-cancel-btn"
        >
          <Icon name="cancel" />
          {t('common.cancel')}
        </Button>
        <div>
          <Button
            primary
            basic
            onClick={() => {
              goBack();
            }}
            data-testid="es-goback-btn"
          >
            <Icon name="arrow alternate circle left outline" data-testid="es-prev-icon" />
            {t('Previous')}
          </Button>
          <Button
            primary
            loading={isSaving}
            onClick={() => {
              setIsSaving(true);
              sendEnrollmentInvitations();
            }}
            data-testid="es-send-invite"
          >
            {t('enrollments.enrollmentInfoSendEnrollmentButton')}
            <Icon name="arrow alternate circle right outline" data-testid="es-icon-info-send-btn" />
          </Button>
        </div>
      </div>
    );
  }

  function renderExtraNotesForm() {
    return (
      <Form>
        <p data-testid="extra-notes">
          <strong>{t('enrollments.enrollmentInfoExtraNotesLabel')}</strong>
        </p>
        <TextArea
          rows={5}
          value={extraNotes}
          onChange={(e, { value }) => {
            if (value) setExtraNotes(value as string);
          }}
          data-testid="extra-notes-textarea"
        />
      </Form>
    );
  }
};

export default EnrollmentPage;
