import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Form } from 'semantic-ui-react';
import Validator from 'validator';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { omit } from 'lodash';

import { useContacts } from '../../../contacts/contactsHooks';

import { InlineError, ShowError, ShowSuccess } from '../../../Components/Messages';

import { FormComponentProps } from '../../../common';
import { InvitationRecipientType } from '../../types';

type InvitationRecipientsFormProps = FormComponentProps<InvitationRecipientType[]> & {
  invitationRecipients: InvitationRecipientType[];
};

const InvitationRecipientsForm: React.FC<InvitationRecipientsFormProps> = ({
  title,
  invitationRecipients,
  isSaving,
  onSave,
}) => {
  const { t } = useTranslation();
  const { contacts } = useContacts();

  const [selectedItems, setSelectedItems] = useState<InvitationRecipientType[]>(
    createSelectedItems(invitationRecipients)
  );
  const [itemEmails, setItemEmails] = useState<Record<string, string | undefined>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (selectedItems.length === 0) {
      errorsLocal['noInvitationRecipients'] = t('enrollments.invitationRecipientsSelectOne');
    }
    // loop through selected items. If the email exists in the itemEmails object, use that value
    // otherwise, use the email from the invitationRecipient object
    // The only way an email would exist in the itemEmails object is if the user has entered it in the input field
    selectedItems.forEach(({ email, contactId }) => {
      const emailLocal = itemEmails[contactId];

      // if recipient is selected but no email is provided
      if (!emailLocal) {
        if (!email) errorsLocal[`email-${contactId}`] = t('common.emailRequired');
      } else {
        if (!Validator.isEmail(emailLocal)) {
          errorsLocal[`email-${contactId}`] = t('Invalid email');
        } else if (email !== emailLocal) {
          const existingContact = contacts.find((contact: any) => {
            return contact.email === emailLocal;
          });
          if (existingContact) {
            errors.email = t('This email is already in use by another user. Please use a different email address');
          }
        }
      }
    });
    return errorsLocal;
  }, [contacts, errors, itemEmails, selectedItems, t]);

  useEffect(() => {
    if (isSaving && onSave) {
      const errorsLocal = validate();
      if (Object.keys(errorsLocal).length > 0) {
        setErrors(errorsLocal);
        onSave?.({ errors: errorsLocal });
      } else {
        selectedItems.forEach((item) => {
          const email = itemEmails[item.contactId];
          if (email) item.email = email;
        });
        onSave?.({ data: selectedItems });
      }
    }
  }, [errors, invitationRecipients, isSaving, itemEmails, onSave, selectedItems, validate]);

  const renderInvitationRecipientItems = useCallback(() => {
    return invitationRecipients.map((invitationRecipient) => {
      const { contactId } = invitationRecipient;
      const error = errors[`email-${contactId}`];
      const isSelected = !!selectedItems.find((item) => item.contactId === contactId);

      return (
        <div className="invitation-recipient" key={contactId}>
          <Checkbox
            checked={isSelected}
            onChange={(e, { checked }) => {
              setErrors((prev) => omit(prev, 'noInvitationRecipients'));
              if (checked) {
                if (!isSelected) setSelectedItems((prev) => [...prev, invitationRecipient]);
              } else {
                if (isSelected) setSelectedItems((prev) => prev.filter((item) => item.contactId !== contactId));
              }
            }}
            data-testid="invitation-recipient"
          />
          <div className="details" data-testid="ir-details">
            <h4 className="name">{invitationRecipient.displayName}</h4>
            {invitationRecipient.email ? (
              <div>{invitationRecipient.email}</div>
            ) : (
              isSelected && (
                <Form.Field error={!!error}>
                  <Form.Input
                    required
                    name={`email-${invitationRecipient.contactId}`}
                    placeholder={t('enrollments.invitationRecipientsEmailPlaceholder')}
                    value={itemEmails[invitationRecipient.contactId] ?? ''}
                    onChange={(e) => {
                      setErrors((prev) => omit(prev, `email-${invitationRecipient.contactId}`));
                      setItemEmails((prev) => ({ ...prev, [invitationRecipient.contactId]: e.target.value }));
                    }}
                    data-testid="ir-email-address"
                  />
                  {!!error && <InlineError text={error} data-testid="ir-error-msg" />}
                </Form.Field>
              )
            )}
          </div>
        </div>
      );
    });
  }, [errors, invitationRecipients, itemEmails, selectedItems, t]);

  const renderBanner = useCallback(() => {
    const bannerMessage = t('enrollments.invitationRecipientsSelectOne');
    const hasErrors = Object.keys(errors).length > 0;

    if (hasErrors) {
      return <ShowError errors={errors} />;
    } else if (selectedItems.length === 0) {
      return <ShowError icon="warning" content={bannerMessage} hideHeader={true} />;
    } else {
      return <ShowSuccess content={bannerMessage} hideHeader={true} />;
    }
  }, [errors, selectedItems, t]);

  return (
    <div className="invitation-recipients-form" data-testid="ir-form">
      <h2 data-testid="ir-form-title">{t(title ?? 'enrollments.invitationRecipientsFormTitle')}*</h2>
      {renderBanner()}
      <div className="invitation-recipients" data-testid="ir-items">
        {renderInvitationRecipientItems()}
      </div>
    </div>
  );
};

function createSelectedItems(invitationRecipients: InvitationRecipientType[]): InvitationRecipientType[] {
  return invitationRecipients.filter((invitationRecipient) => !!invitationRecipient.email);
}

export default InvitationRecipientsForm;
