import { StaffDetail, StaffDetailProps } from '@wonderschool/ccms-ui';
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../config/env';
import { RouteNameEnum, useRoutes } from '../navigation';

const StaffDetailsPage = () => {
  const { t } = useTranslation();
  const { staffId } = useParams<Pick<StaffDetailProps, 'staffId'>>();
  const { gotoRouteByName } = useRoutes();

  const PageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('Staff Details - Wonderschool')}
      </title>
      <body />
    </Helmet>
  );

  return (
    <>
      <PageHead />
      <StaffDetail
        staffId={staffId}
        onDone={() => {
          gotoRouteByName(RouteNameEnum.NEWSTAFF);
        }}
      />
    </>
  );
};

export default StaffDetailsPage;
