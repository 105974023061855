import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Button, Card, Header, Modal, Segment } from 'semantic-ui-react';
// API
import { functions } from '../../../api/firebase';

import { fetchInvoicePlansWithMultipleStudents } from '../../../api/firebase/invoices';
import { useContacts } from '../../../contacts/contactsHooks';
import { useOrganizationContactsListener } from '../../../hooks/useOrganizations';
import { ContactsModal } from './ContactsModal';

const Area51Container = ({ currentOrganization, user: _user }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [stripeCustomerIdInput, setStripeCustomerIdInput] = useState(null);
  const [updatePaymentMethodError, setUpdatePaymentMethodError] = useState(null);
  const [updatePaymentSuccess, setUpdatePaymentSuccess] = useState(null);
  useOrganizationContactsListener(currentOrganization.id);
  const { contacts } = useContacts();
  const [selectedContactUserId, setSelectedContactUserId] = useState();
  const { t } = useTranslation();

  const renderContactsModal = () => {
    const onInputChange = (e) => setStripeCustomerIdInput(e.target.value);

    const onClick = async () => {
      try {
        setUpdatePaymentSuccess(null);
        setUpdatePaymentMethodError(null);
        setLoading(true);
        const { data } = await functions().httpsCallable('callables-stripe-updateUserPaymentMethod')({
          userId: selectedContactUserId,
          stripeCustomerId: stripeCustomerIdInput,
        });
        setLoading(false);
        setUpdatePaymentSuccess(data?.customerId);
      } catch (error) {
        setLoading(false);
        setUpdatePaymentMethodError(error.message);
        console.log(error);
      }
    };

    const onCardClick = (userId) => () => setSelectedContactUserId(userId);
    const getSuccessMessage = (customerId) => `Successfully updated payment method for ${customerId}!`;

    return (
      <ContactsModal
        title="Update payment methods"
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        contacts={contacts || []}
        onInputChange={onInputChange}
        onClick={onClick}
        isLoading={loading}
        error={updatePaymentMethodError}
        infoText="A customerId can be provided if the user object is missing the customer id"
        buttonText="Update payment method"
        selected={selectedContactUserId}
        onCardClick={onCardClick}
        success={updatePaymentSuccess}
        getSuccessMessage={getSuccessMessage}
      />
    );
  };

  const renderPageTitle = () => {
    return (
      <Segment basic clearing>
        <Header as="h1">
          {t('Area 51 👽🛸')}
          <Header.Subheader>{t('We really hope you know what you are doing here')}</Header.Subheader>
        </Header>
      </Segment>
    );
  };

  const renderUserRolesDetail = () => {
    return (
      <Modal
        closeIcon
        centered={false}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{t('Update user roles')}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>{t('More useful stuff to do here...')}</Modal.Description>
        </Modal.Content>
      </Modal>
    );
  };

  const renderActionCards = () => (
    <Segment>
      <Card.Group>
        <Card>
          <Card.Content>
            <Card.Header>{t('Counters')}</Card.Header>
            <Card.Meta>{t('Recalculate all the counters')}</Card.Meta>
            <Card.Description>
              {t(
                'Recalculates all the counters like number of students, staff, checked-in students, rooms, etc... within this organization.'
              )}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Refresh Counts')}
              loading={loading}
              onClick={async () => {
                if (currentOrganization?.id) {
                  try {
                    setLoading(true);
                    await functions().httpsCallable('callables-stats-refreshAllOrganizationStats')({});
                    setLoading(false);
                  } catch (error) {
                    setLoading(false);
                    console.log(error);
                  }
                }
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Permissions')}</Card.Header>
            <Card.Meta>{t('Reset all the permissions')}</Card.Meta>
            <Card.Description>
              {t('This resets ALL the permissions for ALL organizations to the default values. Cuidate!')}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="lock"
              primary
              content={t('Reset All Permissions')}
              loading={loading}
              onClick={async () => {
                setLoading(true);

                try {
                  await functions().httpsCallable('callables-organizations-resetAllPermissions')();
                } catch (error) {
                  console.log(error);
                }

                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Card.Header>{t('Count active invoice plans with multiple students')}</Card.Header>
            <Card.Description>
              {t('Temp util to log active invoice plans that have multiple students.')}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              icon="refresh"
              primary
              content={t('Count plans')}
              loading={loading}
              onClick={async () => {
                if (loading) return;
                setLoading(true);
                const invoicePlans = await fetchInvoicePlansWithMultipleStudents();
                const invoicePlanIds = invoicePlans.map(({ id }) => id);
                console.log(`Invoice plan ids: ${invoicePlanIds}\nCount: ${invoicePlans.length}`);
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Set require id verification flags')}</Card.Header>
            <Card.Description>{t('Set require id verification flag for non-network orgs')}</Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              primary
              content={t('Backfill orgs')}
              icon="refresh"
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await functions().httpsCallable('callables-backfill-setRequireIdVerification')();
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>

        <Card>
          <Card.Content>
            <Card.Header>{t('Update organization onboarding requirements')}</Card.Header>
            <Card.Description>
              {t('Fetches the latest account requirements for the current org from Stripe')}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button
              basic
              fluid
              color="blue"
              primary
              content={t('Update requirements')}
              icon="refresh"
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await functions().httpsCallable('callables-stripe-getPayoutAccountRequirements')({
                  organizationId: currentOrganization.id,
                });
                setLoading(false);
              }}
            />
          </Card.Content>
        </Card>
      </Card.Group>
    </Segment>
  );

  return (
    <>
      <div>{renderContactsModal()}</div>
      <div>{renderPageTitle()}</div>
      <div>{renderActionCards()}</div>
      <div>{renderUserRolesDetail()}</div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps)(Area51Container);
