import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Form, Header, Segment } from 'semantic-ui-react';

// Import actions
import { completeUserSignup } from '../../../redux/actions/userActions';

// Import Components
import LocationTypePicker from '../../Locations/LocationTypePicker';
import InlineError from '../../Messages/InlineError';
import ShowErrors from '../../Messages/ShowError';
import Disclaimer from '../../Shared/Disclaimer';

import { routes } from '../../../config/routes';

class CompleteSignupForm extends Component {
  state = {
    data: {
      organizationName: '',
      programType: '',
      enabled: true,
      language: this.props.i18n.language,
    },
    loading: false,
    errors: {},
  };

  onChange = (e, { name, value }) => {
    this.setState({
      data: { ...this.state.data, [name]: value },
      errors: _.omit(this.state.errors, name),
    });
  };

  onSubmit = async (e) => {
    const { history, signupData, networkId } = this.props;
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });

    if (_.isEmpty(errors)) {
      this.setState({ loading: true });

      const { firstName, lastName, email, phone } = signupData;

      const isFirstOrgUser = true; // this is always true because all other students/staff are invited with a pre-created account via the reset password flow

      try {
        await this.props.completeUserSignup(
          {
            firstName,
            lastName,
            email,
            phone,
            networkId,
            ...this.state.data, // form values override preset in case signupData is missing
          },
          isFirstOrgUser
        );
        history.replace(routes.verify);
      } catch (error) {
        this.setState({
          errors: { 'Sign Up Error': error.message },
          loading: false,
        });
      }
    }
  };

  validate = (data) => {
    const { t } = this.props;
    const errors = {};

    if (!data.organizationName) errors.organizationName = t('Program name is required');
    if (!data.programType) errors.programType = t('Program type is required');
    return errors;
  };

  isSignUpRoute = () => {
    const { match } = this.props;
    return match.path === routes.signup;
  };

  render() {
    const { data, errors, loading } = this.state;
    const { t, signupData } = this.props;

    return (
      <Segment basic textAlign="left">
        <Header as="h1" textAlign="center" content={t('Finish setting up your account')} />

        <br />

        <ShowErrors errors={errors} />

        <Form onSubmit={this.onSubmit} loading={loading} noValidate>
          <Form.Group widths="equal">
            <Form.Field hidden={!!signupData.firstName}>
              <Form.Input
                required
                fluid
                type="text"
                id="firstName"
                name="firstName"
                label={t('common.firstName')}
                placeholder={t('common.firstName')}
                value={signupData.firstName || ''}
              />
            </Form.Field>

            <Form.Field hidden={!!signupData.firstName}>
              <Form.Input
                required
                fluid
                type="text"
                id="lastName"
                name="lastName"
                label={t('common.lastName')}
                placeholder={t('common.lastName')}
                value={signupData.lastName || ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field hidden={!!signupData.email}>
            <Form.Input
              required
              fluid
              type="email"
              id="email"
              name="email"
              label={t('common.email')}
              value={signupData.email || ''}
              autoComplete="username"
            />
          </Form.Field>
          <Form.Field hidden={!!signupData.email}>
            <Form.Input
              required
              fluid
              type="phone"
              id="phone"
              name="phone"
              label={t('Phone')}
              value={signupData.phone || ''}
            />
          </Form.Field>
          <Form.Field error={!!errors.organizationName}>
            <Form.Input
              required
              fluid
              type="text"
              id="organizationName"
              name="organizationName"
              label={t('Name of your program')}
              placeholder={t('Name of your program e.g. Happy Montessori School')}
              value={data.organizationName}
              onChange={this.onChange}
            />
            {errors.organizationName && <InlineError text={errors.organizationName} />}
          </Form.Field>
          <Form.Field error={!!errors.programType}>
            <Form.Field
              translator={t}
              isForm
              required
              id="programType"
              name="programType"
              label={t('Program Type')}
              control={LocationTypePicker}
              placeholder="Select program type"
              value={data.programType}
              selection
              search
              onChange={this.onChange}
            />
            {errors.programType && <InlineError text={errors.programType} />}
          </Form.Field>
          <Form.Button fluid size="large" primary content={t('Finish')} />
          <Form.Field>
            <Disclaimer type="terms" />
          </Form.Field>
        </Form>
      </Segment>
    );
  }
}

CompleteSignupForm.propTypes = {
  completeUserSignup: PropTypes.func.isRequired,
  signupData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  networkId: PropTypes.string,
};

export default withTranslation()(withRouter(connect(null, { completeUserSignup })(CompleteSignupForm)));
