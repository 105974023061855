/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
import { Button, PageLoading, SlideOver } from '@wonderschool/common-base-ui';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getkinderSystemsReports, submitKSReport } from '../../../api/firebase/kinderSystems';
import { logError } from '../../../rollbar';
// import { showErrorToast } from '../../Shared/showToast';
import { useLocations } from '../../../hooks/useLocations';
import { useOrganization } from '../../../hooks/useOrganizations';
import { useStudents } from '../../../students/studentsHooks';

export const KinderConnectReports: React.FC = () => {
  const organization = useOrganization();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [attendanceReports, setAttendanceReports] = useState<any>(null);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const { listFiltered: students } = useStudents();

  useEffect(() => {
    fetchReports();
  }, []);

  const locations = useLocations();

  const fetchReports = async () => {
    try {
      setLoading(true);

      const locationID = locations.selectedLocation?.id || locations.list[0]?.id;
      const reports = await getkinderSystemsReports({
        organizationID: organization.id,
        locationID,
      });
      setAttendanceReports(reports);
    } catch (err) {
      logError('Error fetching reports: ', err);
      // showErrorToast(t('Error fetching reports'), t('Oops, something went wrong!'), err);
    } finally {
      setLoading(false);
    }
  };

  const findSuccessReport = (failedReport) => {
    return attendanceReports?.list.some(
      (report) =>
        report.weekStart === failedReport.weekStart &&
        report.weekEnd === failedReport.weekEnd &&
        report.status === 'success'
    );
  };

  const handleResubmit = async (data) => {
    try {
      setLoading(true);
      const { locationId, kinderSystemReportId } = data;
      const reportData = await submitKSReport({
        locationId,
        kinderSystemReportId,
      });
      if (reportData) {
        await fetchReports();
      }
    } catch (err) {
      logError('Error while resubmitting report: ', err);
      // showErrorToast(t('Error while resubmitting report'), t('Oops, something went wrong!'), err);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (report) => {
    setSelectedReport(report);
    setIsSlideOverOpen(true);
  };

  if (loading) return <PageLoading />;

  if (!attendanceReports?.count) {
    return <p className="text-gray-600">{t('No Reports Submitted')}</p>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left">{t('Sub. Period')}</th>
            <th className="px-6 py-3 text-left">{t('Sub. Date')}</th>
            <th className="px-6 py-3 text-left">{t('Provider ID')}</th>
            <th className="px-6 py-3 text-left">{t('Total Hours')}</th>
            <th className="px-6 py-3 text-left">{t('Status')}</th>
            <th className="px-6 py-3 text-left"></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {attendanceReports?.list.map((report) => (
            <tr key={report.id} onClick={() => handleRowClick(report)} className="cursor-pointer hover:bg-gray-50">
              <td className="px-6 py-4 whitespace-nowrap">
                {`${moment.tz(report.weekStart, report.timezone).format('MMM DD')} - ${moment
                  .tz(report.weekEnd, report.timezone)
                  .format('MMM DD')}`}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment.tz(report.dateOfSubmission, report.timezone).format('MM/DD/YYYY')}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{report.providerID}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {!isNaN(report.totalHours) && Math.floor(report.totalHours / 60) && (
                  <>
                    <span className="font-bold">{Math.floor(report.totalHours / 60)}</span>h{' '}
                    <span className="font-bold">{report.totalHours % 60}</span>m
                  </>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {report.status === 'success' ? (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Transmitted
                  </span>
                ) : (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    Failed
                  </span>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                {report.status !== 'success' && (
                  <Button
                    disabled={findSuccessReport(report)}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleResubmit({
                        locationId: report.locationID,
                        kinderSystemReportId: report.id,
                        organizationId: report.organizationID,
                      });
                    }}
                  >
                    Resubmit
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <SlideOver
        isOpen={isSlideOverOpen}
        hasOverlay={true}
        onClose={() => setIsSlideOverOpen(false)}
        title="Submitted Attendance List"
      >
        {selectedReport && (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="text-left px-6 font-medium ">Date</th>
                  <th className="text-left px-6 font-medium ">Child Name</th>
                  <th className="text-left px-6 font-medium ">Child ID</th>
                  <th className="text-left px-6 font-medium ">Time In</th>
                  <th className="text-left px-6 font-medium ">Time Out</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {selectedReport.attendanceList.map((attendance, index) => {
                  const student = students.find((s) => s.kinderSystems?.kinderConnectId === attendance.childID);
                  return (
                    <tr key={index}>
                      <td className="text-left px-6 py-4 whitespace-nowrap">{attendance.attendanceDate}</td>
                      <td className="text-left px-6 py-4 whitespace-nowrap">
                        {student ? student.displayName : <span className="text-red-600">Unknown</span>}
                      </td>
                      <td className="text-left px-6 py-4 whitespace-nowrap">{attendance.childID}</td>
                      <td className="text-left px-6 py-4 whitespace-nowrap">{attendance.timeIn}</td>
                      <td className="text-left px-6 py-4 whitespace-nowrap">{attendance.timeOut}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <p className="text-gray-600 py-4">
              This report was submitted on{' '}
              {selectedReport &&
                moment.tz(selectedReport.dateOfSubmission, selectedReport.timezone).format('MM/DD/YYYY')}
            </p>
          </div>
        )}
      </SlideOver>
    </div>
  );
};
