import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Form, Grid, Segment } from 'semantic-ui-react';

// Import actions.
import { createInvoice, removeEmployeeSuccess } from '../../../../redux/actions/billingActions';
import { newOrganizationInvoice, saveOrganizationInvoicePlan } from '../../../../redux/actions/invoiceActions';

// Import components
import { withTranslation } from 'react-i18next';
import { routes } from '../../../../config/routes';
import { InvoiceStatus } from '../../../../helpers/invoices';
import { USER_STATUS, USER_STATUS_DESCRIPTION } from '../../../../helpers/userStatus';
import ShowErrors from '../../../Messages/ShowError';
import PageHeader from '../../../Shared/PageHeader';
import PageTitle from '../../../Shared/PageTitle';
import { EmployeeWidget } from '../Sidebar/EmployeeWidget';
import { Steps } from '../Sidebar/Steps';
import Confirm from './Confirm';

class ConfirmContainer extends React.Component {
  static propTypes = {
    paymentDetails: PropTypes.object,
    selectedEmployees: PropTypes.array,
  };

  state = {
    alreadyPaid: false,
    errorMessage: null,
    isConfirmButtonClicked: false,
  };

  renderPageHeader = () => <PageHeader pageName={'Billing'} classes="billing" />;

  onRemoveEmployee = (index) => {
    //remove from redux state
    this.props.removeEmployeeSuccess({ index });
  };

  handleChange = () => this.setState({ alreadyPaid: !this.state.alreadyPaid });

  formatDates = (paymentDetails) => {
    let dateProps = ['dateServiceStart', 'dateServiceEnd', 'dateDue'];
    for (let i = 0; i < dateProps.length; i++) {
      if (paymentDetails[dateProps[i]]) {
        paymentDetails[dateProps[i]] = moment(paymentDetails[dateProps[i]], 'MM/DD/YYYY')
          .tz(this.props.timezone, true)
          .valueOf();
      }
    }
    if (paymentDetails.dateServiceStart && !paymentDetails.dateDue)
      paymentDetails.dateDue = paymentDetails.dateServiceStart;
    return paymentDetails;
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isConfirmButtonClicked: true });
    let { paymentDetails, paymentEmployees, currentOrganization, loggedInUser } = this.props;

    const { alreadyPaid } = this.state;

    if (paymentDetails && paymentEmployees) {
      let { invoiceSummary, ...withoutSummary } = paymentDetails;

      let withFormattedDates = this.formatDates({ ...withoutSummary });

      const students = paymentEmployees.map((paymentEmployee) => {
        let family = {};
        if (paymentEmployee.family) {
          Object.keys(paymentEmployee.family).forEach((key) => {
            const f = paymentEmployee.family[key];
            const { allowParentLogin, familyPin, ...rest } = f;

            family[key] = rest;
          });
        }

        return {
          id: paymentEmployee.id,
          firstName: paymentEmployee.firstName ?? '',
          middleName: paymentEmployee.middleName ?? '',
          lastName: paymentEmployee.lastName ?? '',
          displayName: paymentEmployee.displayName ?? '',
          address1: paymentEmployee.address1 ?? '',
          address2: paymentEmployee.address2 ?? '',
          city: paymentEmployee.city ?? '',
          state: paymentEmployee.state ?? '',
          zipcode: paymentEmployee.zipcode ?? '',
          picture: paymentEmployee.picture ?? '',
          enrollmentStatus: !!paymentEmployee.enrollmentStatus,
          family,
          familyMembers: paymentEmployee.familyMembers || [],
        };
      });

      const payment = {
        details: {
          ...withFormattedDates,
          invoiceItemList: paymentDetails.invoiceItemList.map((item) => {
            let { itemOptions, ...withoutOptions } = item;
            return withoutOptions;
          }),
          alreadyPaid: alreadyPaid,
          manuallyPaid: alreadyPaid,
          paidAmount: alreadyPaid ? paymentDetails.total : 0,
          status: alreadyPaid ? InvoiceStatus.PAID : InvoiceStatus.DRAFT,
          ...(alreadyPaid
            ? { userStatus: USER_STATUS.PAID_MANUALLY, userStatusDescription: USER_STATUS_DESCRIPTION.PAID_MANUALLY }
            : {}),
          createdBy:
            loggedInUser.uid && loggedInUser.displayName
              ? { id: loggedInUser.uid, displayName: loggedInUser.displayName }
              : {},
        },
        items: paymentDetails.invoiceItemList.map((paymentDetail) => {
          const { amount, item } = paymentDetail;

          return {
            amount: amount,
            currency: 'usd',
            description: item,
          };
        }),
        students,
      };

      this.createInvoice(currentOrganization.id, paymentDetails, payment);
    }
  };

  createInvoice = async (organizationId, paymentDetails, payment) => {
    try {
      // by default, return to the main billing page
      let returnRoute = routes.billing.invoices.list;

      if (paymentDetails) {
        // Distinguish the type of invoice: one-time or recurring
        if (paymentDetails.isInvoice) {
          // Save the data as an invoice
          await this.props.newOrganizationInvoice(organizationId, payment);
        } else {
          // Save the data as an invoice plan
          await this.props.saveOrganizationInvoicePlan(organizationId, payment);
          // return to the invoicePlans page
          returnRoute = routes.billing.plans.list;
        }
      }
      // Redirect traffic.
      this.props.history.push(returnRoute);
    } catch (error) {
      console.log(error);
      this.setState({
        ...this.state,
        isConfirmButtonClicked: false,
        errorMessage: this.props.t('We were unable to create your invoice. Please try again later.'),
      });
    }
  };
  render() {
    let { alreadyPaid, errorMessage, isConfirmButtonClicked } = this.state;
    let { t, paymentDetails, paymentEmployees, loading } = this.props;

    return (
      <Segment basic>
        {this.renderPageHeader()}
        {errorMessage && <ShowErrors content={errorMessage} />}

        <Form onSubmit={this.handleSubmit}>
          <Grid stackable reversed={'computer tablet'}>
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <PageTitle data-testid="invoice-confirm-send-title" title={t('Confirm & Send')} />
            </Grid.Column>

            <Grid.Row columns={2}>
              <Grid.Column computer={4} mobile={16} tablet={4}>
                <Steps loading={loading || isConfirmButtonClicked} disabled={isConfirmButtonClicked} />
                <div className={'already-paid-checkbox'}>
                  <Checkbox
                    data-testid="already-paid-checkbox"
                    onChange={this.handleChange}
                    name={'alreadyPaid'}
                    checked={alreadyPaid}
                    label={t('Mark invoice as already paid')}
                  />
                </div>
                {paymentEmployees && (
                  <EmployeeWidget onRemove={this.onRemoveEmployee} selectedEmployees={paymentEmployees} />
                )}
              </Grid.Column>
              <Grid.Column computer={12} mobile={16} tablet={12}>
                {paymentDetails && <Confirm paymentDetails={paymentDetails} />}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.ajaxCallsInProgress > 0,
    paymentDetails: state.paymentDetails,
    paymentEmployees: state.paymentEmployees,
    currentOrganization: state.organizations.currentOrganization,
    timezone: state.locations?.list[0]?.timezone ?? 'utc',
    loggedInUser: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeEmployeeSuccess,
      createInvoice,

      newOrganizationInvoice,
      saveOrganizationInvoicePlan,
    },
    dispatch
  );
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmContainer)));
