import { storage } from '../../api/firebase';
import { logError } from '../../rollbar';
import { AttendanceActivityType } from './types';

// Export the export utils here, so anyone using them will import from this file
export * from './export';

export async function addImageProcess(src): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossorigin', 'anonymous');
    image.src = src;
    image.onload = () => {
      // Draw the image onto a canvas
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        throw new Error('no context');
      }
      // Reduce the size by 50%
      const scaleFactor = 0.5;
      canvas.width = image.width * scaleFactor;
      canvas.height = image.height * scaleFactor;
      // Fill background with white to support JPEG conversion
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, 0, canvas.width, canvas.height);
      // Draw scaled image
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      // * returning the image data URL here results in having the data URL printed in the PDF (long unreadable string)
      resolve(canvas);

      // Clean up the URL object
      URL.revokeObjectURL(src);
    };
    image.onerror = reject;
  });
}

export async function getSignatureImage(signatureUri) {
  try {
    if (signatureUri) {
      // Fetch the SVG data from the URL
      const svgUrl = await storage().ref(signatureUri).getDownloadURL();
      const response = await fetch(svgUrl);
      if (!response) {
        throw Error('unable to get the image');
      }
      const svgBlob = await response.blob();
      const url = URL.createObjectURL(svgBlob);

      // Create an <canvas> element to render the SVG
      const canvas = await addImageProcess(url);

      return canvas;
    } else {
      return '-';
    }
  } catch (err) {
    logError('Image not found: ', signatureUri, err);
    return '-';
  }
}

export function matchAttendanceActivities(activities: any[]): AttendanceActivityType[] {
  try {
    const matchedActivities = [] as any[];
    const leftToMatch = {} as any;

    activities.forEach((activity) => {
      if (activity[activity.type]?.data?.signature) delete activity[activity.type].data.signature;

      if (!activity?.student?.id) {
        logError(`No StudentId found for activity - ${activity.id}`);
        throw Error(`No StudentId found for activity - ${activity.id}`);
      }

      if (activity?.type && activity.type === 'pickup') {
        const activityData = {
          id: activity?.id,
          organization: activity?.organization,
          location: activity?.location,
          student: { ...activity?.student },
          activityTime: activity?.activityTime,
          room: activity?.room,
          checkOut: {
            id: activity?.id,
            activityTime: activity?.activityTime,
            addedBy: { ...activity?.addedBy },
            signatureUri: activity?.signatureUri,
            timezone: activity?.timezone,
            type: activity?.type,
          },
          checkIn: {},
        };

        matchedActivities.push(activityData);
        leftToMatch[activity?.student?.id] = {
          index: matchedActivities.length - 1,
          type: 'pickup',
        };
      }

      if (activity?.type && activity.type === 'absent') {
        const activityData = {
          id: activity?.id,
          organization: activity?.organization,
          location: activity?.location,
          student: { ...activity?.student },
          activityTime: activity?.activityTime,
          room: activity?.room,
          checkIn: {
            id: activity?.id,
            activityTime: activity?.activityTime,
            addedBy: { ...activity?.addedBy },
            signatureUri: activity?.signatureUri,
            timezone: activity?.timezone,
            type: activity?.type,
          },
          checkOut: {},
        };

        matchedActivities.push(activityData);
        delete leftToMatch[activity?.student?.id];
      }

      if (activity?.type === 'dropoff') {
        const activityData = {
          id: activity?.id,
          organization: activity?.organization,
          location: activity?.location,
          student: { ...activity?.student },
          activityTime: activity?.activityTime,
          room: activity?.room,
          checkIn: {
            id: activity?.id,
            activityTime: activity?.activityTime,
            addedBy: { ...activity?.addedBy },
            signatureUri: activity?.signatureUri,
            timezone: activity?.timezone,
            preCheck: activity[activity.type]?.data?.preCheck,
            preCheckData: activity[activity.type]?.data?.preCheckData,
            type: activity?.type,
            note: activity?.dropoff?.data?.note,
          },
          checkOut: {},
        };
        if (
          activity?.student?.id &&
          leftToMatch[activity.student.id] &&
          leftToMatch[activity.student.id].type === 'pickup'
        ) {
          matchedActivities[leftToMatch[activity.student.id].index] = {
            ...matchedActivities[leftToMatch[activity.student.id].index],
            id: matchedActivities[leftToMatch[activity.student.id].index].id + '-' + activityData.id,
            checkIn: { ...activityData.checkIn },
          };
          delete leftToMatch[activity.student.id];
        } else {
          matchedActivities.push(activityData);
          delete leftToMatch[activity.student.id];
        }
      }
    });
    return matchedActivities;
  } catch (error) {
    logError('Error in matchFetchedActivities: ', error);
    throw error;
  }
}
