import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Form } from 'semantic-ui-react';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty, omit } from 'lodash';

import { InlineError /*, ShowError, ShowSuccess*/ } from '../../Components/Messages';
import { DatePicker } from '../../Components/Shared/DatePicker';

import { capitalize } from '../../helpers/utils';
import { FormComponentProps, GenderEnum } from '../../common';
import { StudentType } from '../types';

type StudentEditFormProps = FormComponentProps<StudentType> & {
  student?: StudentType;
};

function StudentEditForm({ title, hasTitle = true, isSaving, onSave, student: studentParam }: StudentEditFormProps) {
  const { t } = useTranslation();

  const [student, setStudent] = useState<StudentType>({} as StudentType);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const viewTitle = hasTitle ? t(title || 'students.studentInformationFormTitle') : '';

  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (!student.firstName) {
      errorsLocal.firstName = t('common.firstNameRequired');
    }
    if (!student.lastName) {
      errorsLocal.lastName = t('common.lastNameRequired');
    }
    return errorsLocal;
  }, [student, t]);

  useEffect(() => {
    if (isSaving && onSave) {
      const errorsLocal = validate();
      if (!isEmpty(errorsLocal)) {
        setErrors(errorsLocal);
        onSave({ errors: errorsLocal });
      } else {
        onSave({ data: student });
      }
    }
  }, [isSaving, onSave, student, validate]);

  useEffect(() => {
    if (studentParam) {
      setStudent(studentParam);
    }
  }, [studentParam]);

  const onChange = useCallback(
    (e: any, { name, value }: any) => {
      setErrors((prev) => omit(prev, name));
      setStudent((prev: StudentType) => ({ ...prev, [name]: value }));
    },
    [setErrors]
  );

  return (
    <div className="new-student-form" data-testid="new-student-form">
      {!!viewTitle && <h2 data-testid="new-student-form-title">{viewTitle}</h2>}
      <Form.Group widths="equal">
        <Form.Field error={!!errors.firstName} data-testid="error-firstname">
          <Form.Input
            required
            name="firstName"
            type="text"
            value={student.firstName ?? ''}
            placeholder={t('common.firstName')}
            label={t('common.firstName')}
            onChange={onChange}
            data-testid="first-name"
          />
          {errors.firstName && <InlineError text={errors.firstName} data-testid="error-firstname-message" />}
        </Form.Field>
        <Form.Field error={!!errors.lastName} data-testid="error-lastname">
          <Form.Input
            required
            name="lastName"
            type="text"
            value={student.lastName ?? ''}
            placeholder={t('common.lastName')}
            label={t('common.lastName')}
            onChange={onChange}
            data-testid="last-name"
          />
          {errors.lastName && <InlineError text={errors.lastName} data-testid="error-lastname-message" />}
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <label>{t('Gender')}</label>
        <Form.Group className="gender-options" data-testid="checkbox-gender">
          <Form.Checkbox
            radio
            label={t(capitalize(GenderEnum.FEMALE))}
            name="gender"
            value={GenderEnum.FEMALE}
            checked={student.gender === GenderEnum.FEMALE}
            onChange={onChange}
            data-testid="checkbox-female"
          />
          <Form.Checkbox
            radio
            label={t(capitalize(GenderEnum.MALE))}
            name="gender"
            value={GenderEnum.MALE}
            checked={student.gender === GenderEnum.MALE}
            onChange={onChange}
            data-testid="checkbox-male"
          />
          <Form.Checkbox
            radio
            label={t(capitalize(GenderEnum.OTHER))}
            name="gender"
            value={GenderEnum.OTHER}
            checked={student.gender === GenderEnum.OTHER}
            onChange={onChange}
            data-testid="checkbox-other"
          />
        </Form.Group>
      </Form.Field>
      <Form.Group widths={2}>
        <Form.Field error={!!errors.birthday} data-testid="birthday-field">
          <DatePicker
            id="birthday"
            name="birthday"
            value={student?.birthday ?? ''}
            onChange={onChange}
            label={t('Birthday')}
            error={!!errors.birthday}
            closable
          />
          {errors.birthday && <InlineError text={errors.birthday} data-testid="error-birthday" />}
        </Form.Field>
      </Form.Group>
    </div>
  );
}

export default StudentEditForm;
