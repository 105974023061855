// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import { auth } from '.';
import store from '../../redux/store';
import { provisionOrganizationUserAccount } from './organizations';

export const userHasRole = async (role) => {
  if (role && auth().currentUser && auth().currentUser.emailVerified) {
    const idTokenResult = await auth().currentUser.getIdTokenResult();
    if (idTokenResult.claims[role] && idTokenResult.claims[role] === true) return true;
  }
  return false;
};

export const userHasOneOfRoles = (roles) => {
  const user = store.getState().user || {};
  if (
    roles &&
    roles.length &&
    auth().currentUser &&
    auth().currentUser.emailVerified &&
    !_.isEmpty(user) &&
    !_.isEmpty(user.claims)
  )
    return roles.some((role) => user.claims[role] === true);

  return false;
};

// Rule to check restriction or orgType.
export const hasNoOrgRestriction = (routeName) => {
  const { currentOrganization } = store.getState().organizations;

  // Restrict Billing for home care.
  if (currentOrganization?.programType === 'familyChildCare' && routeName === 'Rooms') return false;

  return true; // if no org, totally ok
};

export const userHasPermission = (permissionKey) => {
  const permissions = store.getState().permissions || {};
  const user = store.getState().user || {};

  // Override all permissions for moxitAdmin claims.
  // Not this doesn't mean data is protected. Firestore rule MUST be in place
  // to control data read/write.
  if (user && user.claims && !!user.claims.moxitAdmin) return true;
  if (permissionKey && permissions[permissionKey] && permissions[permissionKey].roles) {
    const allowedRoles = permissions[permissionKey].roles || [];
    return userHasOneOfRoles(allowedRoles);
  }
  return false;
};

export const startProvisioning = (userData) => {
  const user = auth().currentUser;
  if (!user.uid) return;

  let profileData = {
    uid: user.uid,
    email: user.email,
    ...userData,
  };

  if (userData.firstName && userData.lastName) profileData.displayName = `${userData.firstName} ${userData.lastName}`;

  return provisionOrganizationUserAccount(profileData);
};

export const sendResetPasswordEmail = async (email) => auth().sendPasswordResetEmail(email);
