import { MenuPlanningViewWeek } from '@wonderschool/common-food-program-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { getTitlePrefix } from '../../../config/env';
import { RouteNameEnum, useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

const MenuPlanning = () => {
  const { t } = useTranslation();
  const title = t('food.menu.planning');
  const { gotoRouteByName } = useRoutes();

  useSegmentPage(PAGE_NAMES.foodProgramMenuPlanning);

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body />
    </Helmet>
  );

  const handleClickEditMeals = (date: Date) => {
    gotoRouteByName(RouteNameEnum.FOOD_PROGRAM_MENU_PLANNING_EDIT, [
      {
        name: 'date',
        value: date.toISOString().split('T')[0],
      },
    ]);
  };

  return (
    <>
      {renderPageHead()}
      <MenuPlanningViewWeek onClickEditMeals={handleClickEditMeals} onClickPrintMenu={() => null} />
    </>
  );
};
export default MenuPlanning;
