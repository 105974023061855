import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Header } from 'semantic-ui-react';

import { FileDocument, FileCategory, useUploadFile } from '@wonderschool/file-service-client';

import { ShowError, ShowSuccess, ShowWarning } from '../../../Components/Messages';
import DocumentUploader from '../../../Components/Upload/DocumentUploader';

import { FormComponentProps } from '../../../common';
import { WsDocumentUpload } from '../../../icons';
import { logError } from '../../../rollbar';

import { useOrganization } from '../../../hooks/useOrganizations';
import { DocumentType } from '../../types';
import { allowedMimeTypes } from '../../../documents/dictionary';

import { StaffDocumentsTable as DocumentsTable } from '../../../documents';

type DocumentsFormProps = FormComponentProps<DocumentType[]> & {
  documents?: DocumentType[];
};

const DocumentsForm: React.FC<DocumentsFormProps> = ({ title, documents, isSaving, onSave }) => {
  const { t } = useTranslation();
  const organization = useOrganization();
  const { uploadFile, error: uploadError, isSuccess: uploadSuccess, reset: resetUploadHook } = useUploadFile();
  const { id: currentOrganizationId } = useOrganization();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<DocumentType[]>(documents ?? []);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (selectedDocuments.length === 0) {
      errorsLocal['documents'] = t('enrollments.documentsSelectOne');
    }
    return errorsLocal;
  }, [selectedDocuments, t]);

  useEffect(() => {
    if (!isSaving) return;

    const errorsLocal = validate();
    if (Object.keys(errorsLocal).length > 0) {
      setErrors(errorsLocal);
      onSave?.({ errors: errorsLocal });
    } else {
      onSave?.({ data: selectedDocuments });
    }
  }, [errors, isSaving, onSave, selectedDocuments, validate]);

  useEffect(() => {
    if (uploadSuccess && fileToUpload) {
      // reset the file
      setFileToUpload(null);
      // reset the hook
      resetUploadHook();
    }
  }, [fileToUpload, resetUploadHook, uploadSuccess]);

  const renderBanner = useCallback(() => {
    const bannerMessage = t('enrollments.documentsSelectOne');

    if (selectedDocuments.length > 0) {
      return <ShowSuccess content={bannerMessage} hideHeader={true} />;
    } else if (Object.keys(errors).length > 0) {
      return <ShowError content={bannerMessage} hideHeader={true} />;
    } else {
      return <ShowWarning content={bannerMessage} hideHeader={true} />;
    }
  }, [errors, selectedDocuments, t]);

  const handleSelectedDocuments = useCallback((selectedDocuments: FileDocument[]) => {
    const selectedDocumentsLocal = selectedDocuments.map(
      (document) =>
        ({
          id: document.id,
          name: document.name,
          url: document.fileURL,
        }) as DocumentType
    );
    setSelectedDocuments(selectedDocumentsLocal);
  }, []);

  // upload stuff
  const onFileSelected = useCallback(({ data: file }: { data: File }) => {
    if (file) {
      setFileToUpload(file);
    }
  }, []);

  const onFileSubmit = useCallback(async () => {
    if (!fileToUpload) {
      return null;
    }

    try {
      // start the request
      uploadFile({
        file: fileToUpload,
        options: {
          fileAction: 'downloadable',
          fileCategory: FileCategory.FORM,
          metadata: {
            uploadPath: `/organizations/${currentOrganizationId}`,
          },
        },
      });

      // close the modal
    } catch (error) {
      // log it with rollbar
      logError('Documents - Upload error: ', {
        clientError: error,
        serverMessage: uploadError,
      });
    }

    return null;
  }, [currentOrganizationId, fileToUpload, uploadError, uploadFile]);
  return (
    <div className="ws-form" data-testid="docs-upload-wrap">
      <div className="ws-form-title" data-testid="docs-upload-title">
        <Header as="h2">{t(title ?? 'enrollments.documentsFormTitle', { name: organization.name })}*</Header>
        <DocumentUploader
          onFileSelected={onFileSelected}
          onSubmit={onFileSubmit}
          onError={() => null}
          onClose={() => setIsModalOpen(false)}
          shouldUseExternalStorageApi
          title={t('Upload Files')}
          allowedFileTypes={allowedMimeTypes}
          maxNumberOfFiles={1}
          modalOverrideProps={{
            trigger: <UploadLink />,
            open: isModalOpen,
          }}
          data-testid="docs-uploader"
        />
      </div>

      {renderBanner()}
      <DocumentsTable
        onRowSelect={handleSelectedDocuments}
        hideBanner={true}
        selectedDocumentIds={selectedDocuments.map((document) => document.id)}
      />
    </div>
  );

  function UploadLink() {
    return (
      <div>
        <a
          href="#"
          className="ws-icon-link"
          onClick={() => {
            setIsModalOpen(true);
          }}
          data-testid="docs-icon-link"
        >
          <WsDocumentUpload />
          <span>{t('enrollments.documentUploadButton')}</span>
        </a>
      </div>
    );
  }
};

export default DocumentsForm;
