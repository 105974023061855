import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { Checkbox, Form } from 'semantic-ui-react';
// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import { isEmpty, omit } from 'lodash';

import RoomPicker from '../../../Components/Rooms/RoomPicker';
import { InlineError } from '../../../Components/Messages';
import { DatePicker } from '../../../Components/Shared/DatePicker';

import { dateFormatter } from '../../../helpers/dates';
import { FormComponentProps } from '../../../common';
import { EnrollmentInformationType } from '../../types';
import { DEFAULT_ENROLLMENT_INFORMATION } from '../../consts';

type EnrollmentInformationFormProps = FormComponentProps<EnrollmentInformationType> & {
  enrollment?: EnrollmentInformationType;
  desiredStartDate?: Date;
  desiredRoomId?: string;
};

function EnrollmentInformationForm({
  title,
  hasTitle = true,
  isSaving,
  onSave,
  enrollment: enrollmentParam,
  desiredStartDate,
  desiredRoomId,
}: EnrollmentInformationFormProps) {
  const { t } = useTranslation();
  const [enrollmentInformation, setEnrollmentInformation] = useState<EnrollmentInformationType>(
    enrollmentParam ?? DEFAULT_ENROLLMENT_INFORMATION
  );

  const [errors, setErrors] = useState<Record<string, string>>({});

  const viewTitle = hasTitle ? t(title || 'students.enrollmentInformationFormTitle') : '';

  const startDate = useMemo(() => {
    return enrollmentInformation.startDate
      ? dateFormatter(enrollmentInformation.startDate)
      : desiredStartDate
        ? dateFormatter(desiredStartDate)
        : '';
  }, [desiredStartDate, enrollmentInformation]);

  useEffect(() => {
    // If desiredStartDate is set, we want to re-check automaticallyEnroll, even if the user has already unchecked it.
    setEnrollmentInformation((prev) => ({
      ...prev,
      room: desiredRoomId || '',
      startDate: desiredStartDate,
      automaticallyEnroll: true,
    }));
  }, [desiredStartDate, desiredRoomId]);

  const validate = useCallback(() => {
    const errorsLocal: Record<string, string> = {};
    if (!enrollmentInformation.room) {
      errorsLocal.room = t('Please select a room');
    }
    if (!enrollmentInformation.startDate) {
      errorsLocal.startDate = t('enrollments.enrollmentInfoStartDateRequired');
    }
    return errorsLocal;
  }, [enrollmentInformation, t]);

  useEffect(() => {
    if (isSaving && onSave) {
      const errorsLocal = validate();
      if (!isEmpty(errorsLocal)) {
        setErrors(errorsLocal);
        onSave({ errors: errorsLocal });
      } else {
        onSave({ data: enrollmentInformation });
      }
    }
  }, [isSaving, onSave, enrollmentInformation, validate]);

  const onChangeStartDate = useCallback(
    (e: any, { name, value, valid }: any) => {
      if (!valid) {
        setErrors({ ...errors, [name]: t('Invalid Date') });
      } else {
        setErrors((prev) => omit(prev, name));
        setEnrollmentInformation((prev) => ({ ...prev, [name]: new Date(value) }));
      }
    },
    [errors, t]
  );
  const onChange = useCallback((_: any, { name, value, checked }: any) => {
    setEnrollmentInformation((prev) => ({ ...prev, [name]: checked ?? value }));
    setErrors((prev) => omit(prev, name));
  }, []);

  return (
    <Form className="enrollment-information-form">
      {!!viewTitle && <h2>{viewTitle}</h2>}
      <Form.Group widths="equal">
        <Form.Field error={!!errors.room} data-testid="room-picker-field">
          <RoomPicker
            required
            name="room"
            label={t('Room')}
            placeholder={t('Select a room')}
            value={enrollmentInformation.room || desiredRoomId || ''}
            selection
            search
            onChange={onChange}
          />
          {!!errors.room && <InlineError text={errors.room} data-testid="error-select-room" />}
        </Form.Field>
        <Form.Field error={!!errors.startDate} data-testid="date-picker-field">
          <DatePicker
            required
            id="startDate"
            name="startDate"
            value={startDate}
            onChange={onChangeStartDate}
            label={t('enrollments.enrollmentInfoStartDateLabel')}
            error={!!errors.startDate}
            closable
          />
          <div>
            {errors.startDate && <InlineError text={errors.startDate} data-testid="error-start-date" />}
            <Checkbox
              checked={!!enrollmentInformation.automaticallyEnroll}
              name="automaticallyEnroll"
              label={t('students.automaticallyEnrollLabel')}
              onChange={onChange}
              data-testid="enroll-automatically"
            />
          </div>
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

export default EnrollmentInformationForm;
