import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { Header, Segment, Table } from 'semantic-ui-react';
import { WsModal } from '../../common';

// Import components.
import { updateMoxitOrganization } from '../../redux/actions/organizationActions';
import DocumentUploader from './DocumentUploader';

import { formatLocaleDate } from '../../helpers/dates';
import { formatFullName, humanReadableFileSize } from '../../helpers/utils';
import useRooms from '../../hooks/useRooms';
import SectionCard from '../Shared/SectionCard';
import studentsOnboardingCSV from './assets/wonderschool-onboarding-student.csv';
import ImportDataModalResult from './ImportStudentDataResult';
import StudentsCSVPlugin from './StudentsCSVPlugin';
import UppyCSV from './UppyCSV';
import { Label } from '@wonderschool/common-base-ui';

// This is the only way I could figure how to pass shouldInvite to the plugin.
let shouldInviteGlobal = false;
const shouldInviteCallback = () => shouldInviteGlobal;

const StudentsImporter = ({ currentOrganization, user, updateMoxitOrganization }) => {
  const [uploaded, setUploaded] = useState(false);
  const [shouldInvite, setShouldInvite] = useState(shouldInviteGlobal);
  const [isShowModalImportStudentDetails, setIsShowModalImportStudentDetails] = useState(false);
  const [resultData, setResultData] = useState(null);
  const rooms = useRooms();

  const { t } = useTranslation();

  const uppyPlugins = useMemo(() => {
    return [
      {
        plugin: UppyCSV,
        options: {
          csvTemplate: studentsOnboardingCSV,
          setResultData: setResultData,
          setIsShowModalImportStudentDetails: setIsShowModalImportStudentDetails,
        },
        restrictions: {
          allowedFileTypes: ['.csv', 'text/csv'],
        },
      },
      {
        plugin: StudentsCSVPlugin,
        options: {
          shouldInviteCallback: shouldInviteCallback,
          setResultData: setResultData,
          setIsShowModalImportStudentDetails: setIsShowModalImportStudentDetails,
          rooms: rooms,
        },
      },
    ];
  }, [rooms]);

  // Set upload path.
  const uploadPath = `organizations/${currentOrganization.id}/imports`;

  const onUploaded = async (successful) => {
    if (successful && successful.length) {
      const { uid, email } = user;

      // Set is uploaded flag to true.
      setUploaded(true);
      setIsShowModalImportStudentDetails(true);

      const imports = currentOrganization?.imports || [];

      successful.forEach((uploaded) => {
        const name = uploaded.name ? uploaded.name.split('.')[0] : t('Unknown');
        const id = uploaded?.meta?.refId?.split('.')[0];

        imports.push({
          id,
          name,
          path: uploadPath,
          type: uploaded.type,
          size: uploaded.size,
          extension: uploaded.extension,
          progress: uploaded.progress,
          meta: uploaded.meta,
          downloadUrl: uploaded.downloadUrl,
          uploadedBy: { uid, email, name: formatFullName(user, true) },
          uploadedDate: Date.now(),
          tags: [],
          status: t('processing'),
        });
      });

      try {
        await updateMoxitOrganization({ ...currentOrganization, imports });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderUploadedList = () => {
    const { imports } = currentOrganization;

    if (!imports || !imports.length) {
      return null;
    }

    return (
      <SectionCard header={t('Upload History')}>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('File name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Uploaded by')}</Table.HeaderCell>
              <Table.HeaderCell>{t('Date')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {imports.map((importItem, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <a href={importItem?.downloadUrl} alt={importItem?.name}>
                    {importItem?.name}
                  </a>{' '}
                  <div className="text-muted">
                    {humanReadableFileSize(importItem?.size)} &middot; {importItem?.meta?.refId}
                  </div>
                </Table.Cell>
                <Table.Cell>{importItem?.uploadedBy?.name}</Table.Cell>
                <Table.Cell>{formatLocaleDate(importItem?.uploadedDate)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </SectionCard>
    );
  };

  const renderPageTitle = () => {
    return (
      <Segment basic clearing>
        <Header as="h1" content={t('Import your students list')} />
      </Segment>
    );
  };

  return (
    <div className="container mx-auto">
      {/* Page Title */}
      <div className="flex">{renderPageTitle()}</div>

      {/* Conditional rendering for file upload steps or success message */}
      {!uploaded ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center border-2 border-color:text-gray-800 p-4">
          {/* Instructions List */}
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="my-2">
                <div className="flex flex-row gap-2">
                  <Label extraClasses="font-bold text-md">1</Label>
                  <Label extraClasses="font-bold text-md">{t('Download a template')}</Label>
                </div>
                <div className="ml-3">
                  <Trans i18nKey="To begin the importing process, download the CSV template.">
                    To begin the importing process, download the{' '}
                    <a href={studentsOnboardingCSV} className="text-blue-500">
                      CSV template
                    </a>
                    .
                  </Trans>
                </div>
              </div>
              <div className="my-2">
                <div className="flex flex-row gap-2">
                  <Label extraClasses="font-bold text-md">2</Label>
                  <Label extraClasses="font-bold text-md">{t("Add your student's information")}</Label>
                </div>
                <div className="ml-3">
                  {t(
                    "Fill out the template with your students' information. " +
                      'If using Excel, make sure to export or save as a .csv file.'
                  )}
                </div>
              </div>
              <div className="my-2">
                <div className="flex flex-row gap-2">
                  <Label extraClasses="font-bold text-md">3</Label>
                  <Label extraClasses="font-bold text-md">{t('Select to send parent invitation during upload')}</Label>
                </div>
                <div className="ml-3">
                  {t(
                    'Select check mark if you like to bulk send parent invitations to join Wonderschool ' +
                      'and verify their accounts to access parent app during file upload. ' +
                      'You also have the option to do this later via student profile if you uncheck the checkbox.'
                  )}
                </div>
              </div>
              <div className="my-2">
                <div className="flex flex-row gap-2">
                  <Label extraClasses="font-bold text-md">4</Label>
                  <Label extraClasses="font-bold text-md">{t('Upload file')}</Label>
                </div>
                <div className="ml-3">
                  <Label extraClasses="text-md">{t('Upload the filled out template.')}</Label>
                </div>
              </div>
            </div>

            <p className="text-gray-500 mt-4">
              {t(
                'Note: The importing process may take a few minutes to process. ' +
                  'Any duplicate students and students without rooms that do not ' +
                  'exist in the current account will be ignored.'
              )}
            </p>
          </div>

          {/* File Upload Area */}
          <div className="bg-gray-100 rounded-md shadow-md p-4">
            <div className="flex flex-row gap-4 content-center bg-white p-4 m-4 mb-4 border-2 border-color:text-gray-800">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 mt-6"
                checked={shouldInvite}
                onChange={(_e) => {
                  setShouldInvite(!_e.target.checked);
                  shouldInviteGlobal = !_e.target.checked;
                }}
              />
              <Label extraClasses="inline-flex items-center text-md">
                {t(
                  'Bulk send parent invitation to join Wonderschool and verify accounts during this file upload process.'
                )}
              </Label>
            </div>

            <div className="text-center flex flex-col items-center">
              <Label extraClasses="text-lg font-semibold">{t('Upload your students list.')}</Label>
              <div className="mt-4">
                <DocumentUploader
                  uploadPath={uploadPath}
                  title={t('Uploading Student Documents')}
                  allowedFileTypes={['.csv', 'text/csv']}
                  onUploaded={onUploaded}
                  maxNumberOfFiles={1}
                  plugins={uppyPlugins}
                  isShowModalImportStudentDetails={isShowModalImportStudentDetails}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        /* Success message */
        <div className="flex justify-center items-center flex-col text-center">
          <div className="bg-green-500 text-white rounded-full p-4">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold mt-2">{t('Uploaded successfully')}</h2>
          <p className="text-gray-500 mt-2">
            <Trans
              i18nKey="Your student roster has been uploaded successfully. Depending
        on the size of the roster, it could take about 5 minutes for
        the students to be added to your account. When ready, the
        students will show up on the on the Students page."
            >
              Your student roster has been uploaded successfully. Depending on the size of the roster, it could take
              about 5 minutes for the students to be added to your account. When ready, the students will show up on the
              on the
            </Trans>
          </p>
        </div>
      )}

      {/* Uploaded List */}
      <div className="mt-8">{renderUploadedList()}</div>

      {/* Help Section */}
      <div className="text-center mt-8">
        <Trans
          i18nKey="students.import.needHelp"
          components={{
            a: <a href="mailto:hello@wonderschool.com" className="text-blue-500 underline" />,
          }}
        />
      </div>

      {/* Import Data Modal */}
      <WsModal
        isOpen={isShowModalImportStudentDetails}
        onClose={() => setIsShowModalImportStudentDetails(false)}
        title={t('Import students report')}
      >
        <div className="flex w-96 flex-col justify-center gap-2">
          <ImportDataModalResult resultData={resultData} />
        </div>
      </WsModal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  currentOrganization: state.organizations.currentOrganization,
});

export default connect(mapStateToProps, { updateMoxitOrganization })(StudentsImporter);
