import { MenuPlanningEdit } from '@wonderschool/common-food-program-ui';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getTitlePrefix } from '../../../config/env';
import { useRoutes } from '../../../navigation';
import { PAGE_NAMES, useSegmentPage } from '../../../segment';

interface MenuPlanningUpdateRouteParams {
  date: string;
}

const MenuPlanningUpdate = () => {
  useSegmentPage(PAGE_NAMES.foodProgramMenuPlanningUpdate);

  const { t } = useTranslation();
  const title = t('food.menu.planning');
  const { goBack } = useRoutes();
  const { date } = useParams<MenuPlanningUpdateRouteParams>();

  const renderPageHead = () => (
    <Helmet>
      <title>
        {getTitlePrefix()}
        {t('{{pageName}} - Wonderschool', { pageName: title })}
      </title>
      <body />
    </Helmet>
  );

  const menuEventDate = dayjs(date || new Date().toISOString()).toDate();

  return (
    <>
      {renderPageHead()}
      <MenuPlanningEdit menuEventDate={menuEventDate} onClickBack={goBack} />
    </>
  );
};
export default MenuPlanningUpdate;
