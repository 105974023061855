import {
  createOrganizationInvoice,
  createOrganizationInvoicePlan,
  fetchOrganizationInvoice,
  fetchOrganizationInvoicePlans,
  fetchOrganizationInvoices,
  organizationInvoicesOnListen,
  organizationParentInvoicesOnListen,
  removeOrganizationInvoice,
  setOrganizationInvoicePlan,
} from '../../api/firebase/invoices';

import {
  INVOICES_FETCHED,
  INVOICES_LISTENER_STARTED,
  INVOICE_ADDED,
  INVOICE_ADDED_TO_LIST,
  INVOICE_PLANS_FETCHED,
  INVOICE_REFRESHED,
  INVOICE_REMOVE_FROM_LIST,
  INVOICE_SELECTED,
  INVOICE_SELECTION_CLEARED,
  INVOICE_SET,
  INVOICE_UPDATED,
} from './types';

// Import billing actions
import { userHasPermission } from '../../api/firebase/account';
import { logError } from '../../rollbar';
import { resetPaymentData as resetPaymentDataAction } from './billingActions';

import { isV2Invoice, mapV2Invoice } from '../../helpers/invoices';
import { InvoiceType } from '../reducers/billing/invoices';

export const invoicesListenerStarted = () => ({
  type: INVOICES_LISTENER_STARTED,
});

export const invoicesFetched = (invoices, invoiceType) => ({
  type: INVOICES_FETCHED,
  invoices,
  invoiceType,
});

export const invoicePlansFetched = (invoices) => ({
  type: INVOICE_PLANS_FETCHED,
  invoices,
  invoiceType: InvoiceType.RECURRING,
});

export const invoiceRefreshed = (invoice, invoiceType) => ({
  type: INVOICE_REFRESHED,
  invoice,
  invoiceType,
});

export const invoiceAdded = () => ({
  type: INVOICE_ADDED,
});

export const invoiceSet = () => ({
  type: INVOICE_SET,
});

export const invoiceUpdated = (invoice, invoiceType) => ({
  type: INVOICE_UPDATED,
  invoice,
  invoiceType,
});

export const invoiceSelected = (invoice, invoiceType) => {
  return {
    type: INVOICE_SELECTED,
    invoice,
    invoiceType,
  };
};

export const invoiceSelectionCleared = () => ({
  type: INVOICE_SELECTION_CLEARED,
});

export const invoiceAddedToList = (invoice) => ({
  type: INVOICE_ADDED_TO_LIST,
  invoice,
});

export const invoiceRemovedFromList = (id) => ({
  type: INVOICE_REMOVE_FROM_LIST,
  id,
});

export const getOrganizationInvoices =
  (organizationId, conditions, orderBy = [], limit, last, invoiceType) =>
  async (dispatch) => {
    try {
      const invoicesData = await fetchOrganizationInvoices(organizationId, conditions, orderBy, limit, last);

      // We are passing the invoice type is because we have three type of invoices
      // we want to fetch for: everything, one-off and recurring.
      return dispatch(invoicesFetched(invoicesData, invoiceType));
    } catch (error: unknown) {
      logError('error getting organization invoices', error);
      if (typeof error === 'string') {
        throw Error(error);
      }
    }
  };

export const getOrganizationInvoicePlans =
  (organizationId, conditions = [], orderBy = [], limit = null, last = null) =>
  async (dispatch) => {
    try {
      const invoicesData = await fetchOrganizationInvoicePlans(organizationId, conditions, orderBy, limit, last);

      return dispatch(invoicePlansFetched(invoicesData));
    } catch (error: unknown) {
      logError(error);
      if (typeof error === 'string') {
        throw Error(error);
      }
    }
  };

export const startOrganizationParentInvoicesListener = (organizationId, contactId) => (dispatch) => {
  if (!userHasPermission('can_list_student_invoices_as_parent')) return null;

  dispatch(invoicesListenerStarted());

  return organizationParentInvoicesOnListen(
    { organizationId, contactId },
    (data) => {
      if (data) dispatch(invoicesFetched(data, 'all'));
    },
    (error) => console.log(error.message)
  );
};

export const startOrganizationInvoicesListener = (organizationId, locations) => (dispatch) => {
  if (!userHasPermission('can_list_billing')) return null;

  dispatch(invoicesListenerStarted());

  return organizationInvoicesOnListen(
    { organizationId, locations },
    async (data) => {
      if (data) {
        const invoices = data.list.map((invoice) => (isV2Invoice(invoice) ? mapV2Invoice(invoice) : invoice));
        dispatch(invoicesFetched({ ...data, list: invoices }, 'all'));
      }
    },
    (error) => console.log(error.message)
  );
};

export const resetPaymentData = () => async (dispatch) => {
  try {
    // Clear out the billing store
    return dispatch(resetPaymentDataAction());
  } catch (error: unknown) {
    logError(error);
    if (typeof error === 'string') {
      throw Error(error);
    }
  }
};
export const newOrganizationInvoice = (organizationId, payload) => async (dispatch) => {
  try {
    // Save the data
    await createOrganizationInvoice(organizationId, payload);

    // Clear out the billing store
    dispatch(resetPaymentData());

    return dispatch(invoiceAdded());
  } catch (error: unknown) {
    logError(error);
    if (typeof error === 'string') {
      throw Error(error);
    }
  }
};

export const saveOrganizationInvoicePlan = (organizationId, payload) => async (dispatch) => {
  try {
    if (payload.id) {
      await setOrganizationInvoicePlan(organizationId, payload);
    } else {
      await createOrganizationInvoicePlan(organizationId, payload);
    }
    return dispatch(getOrganizationInvoicePlans(organizationId));
  } catch (error: unknown) {
    logError(error);
    if (typeof error === 'string') {
      throw Error(error);
    }
  }
};

export const deleteOrganizationInvoice = (organizationId, invoiceId) => (dispatch) => {
  try {
    // Immediately remove from Redux store.
    dispatch(invoiceRemovedFromList(invoiceId));
    return removeOrganizationInvoice(organizationId, invoiceId);
  } catch (error: unknown) {
    logError(error);
    if (typeof error === 'string') {
      throw Error(error);
    }
  }
};

export const refreshOrganizationInvoice = (organizationId, invoiceId, invoiceType) => async (dispatch) => {
  try {
    const invoice = await fetchOrganizationInvoice(organizationId, invoiceId);
    return dispatch(invoiceRefreshed(invoice, invoiceType));
  } catch (error: unknown) {
    logError(error);
    if (typeof error === 'string') {
      throw Error(error);
    }
  }
};
