// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { Invoice } from '@wonderschool/ws-types';
import { InvoiceListData } from '../redux/reducers/billing/invoices';
import { doesQueryMatchStudent } from '../students/studentsUtils';
import { INVOICE_STATUS } from './invoiceStatusV2';
import { InvoiceStatus, isInvoicePaid, isV2Invoice } from './invoices';
import { USER_STATUS } from './userStatus';

const doesMatchUserStatus =
  (filter) =>
  ({ userStatus, status }) =>
    userStatus === filter && status !== InvoiceStatus.VOID && status !== InvoiceStatus.UNCOLLECTIBLE;

export const filterV2InvoiceStatus = (invoice, statusFilter) => {
  const { status, markedUncollectibleAt } = invoice;

  switch (statusFilter) {
    case `${InvoiceStatus.PAID}(O)`:
      return status === INVOICE_STATUS.PAID_MANUALLY;
    case InvoiceStatus.FAILED:
      // InvoiceStatus.FAILED is a V1 status filter. But since it's still an option I return CHARGE_FAILED V2 invoices (same as choosing "Charging Failed" filter).
      return status === INVOICE_STATUS.CHARGE_FAILED;
    case InvoiceStatus.OPEN:
      return status === INVOICE_STATUS.INVOICE_SENT;
    case InvoiceStatus.PAID:
      return (
        status !== INVOICE_STATUS.PAID_MANUALLY &&
        [INVOICE_STATUS.DEPOSITED, INVOICE_STATUS.QUICK_DEPOSITED].includes(status)
      );
    case InvoiceStatus.UNCOLLECTIBLE:
      // "Uncollectible" status can get overwritten by payout reversal flow. So instead we check for markedUncollectibleAt.
      return markedUncollectibleAt !== undefined && markedUncollectibleAt !== null;
    default:
      return status === statusFilter;
  }
};

export const filterInvoiceStatus = (invoiceList, invoiceStatusFilter) =>
  invoiceList.filter((invoice) => {
    if (isV2Invoice(invoice)) {
      return filterV2InvoiceStatus(invoice, invoiceStatusFilter);
    }

    const { status, manuallyPaid, emailNotifications, userStatus } = invoice;

    const hasSentEmailNotification = !!(emailNotifications && emailNotifications.length > 0);

    if (invoiceStatusFilter === USER_STATUS.NOTIFICATION_SENT)
      return (
        hasSentEmailNotification && !userStatus && (status === InvoiceStatus.OPEN || status === InvoiceStatus.SCHEDULED)
      );

    if (invoiceStatusFilter === USER_STATUS.SCHEDULED || invoiceStatusFilter === USER_STATUS.PROCESSING)
      return doesMatchUserStatus(invoiceStatusFilter)(invoice);

    if (invoiceStatusFilter === InvoiceStatus.OPEN)
      return (
        (!hasSentEmailNotification && status === invoiceStatusFilter && !userStatus) ||
        doesMatchUserStatus(USER_STATUS.INVOICE_SENT)(invoice)
      );

    if (invoiceStatusFilter === `${InvoiceStatus.PAID}(O)`)
      return (
        (Boolean(manuallyPaid) && status === InvoiceStatus.PAID) ||
        doesMatchUserStatus(USER_STATUS.PAID_MANUALLY)(invoice)
      );

    if (invoiceStatusFilter === InvoiceStatus.PAID) return !manuallyPaid && isInvoicePaid(invoice);

    if (invoiceStatusFilter === InvoiceStatus.FAILED)
      return userStatus ? doesMatchUserStatus(USER_STATUS.CHARGE_FAILED)(invoice) : status === InvoiceStatus.FAILED;

    return status === invoiceStatusFilter || doesMatchUserStatus(invoiceStatusFilter)(invoice);
  });

/**
 * This function filters a list of invoices by the description of the invoice items.
 * It will return invoices where the description of any of the invoice items contains the search string.
 * If the search string is empty, it will return the original list of invoices.
 * Otherwise, it will return a filtered list of invoices.
 *
 * @param invoiceList The list of invoices to filter
 * @param descriptionSearch The description to filter by, as a string
 * @returns The filtered list of invoices
 */
export const filterDescriptions = (invoiceList: Invoice[], descriptionSearch: string) =>
  invoiceList.filter(({ invoiceItemList }) => {
    const description = invoiceItemList.reduce((acc, { item }) => acc + item, '');

    return description.toLowerCase().includes(descriptionSearch.toLowerCase());
  });

/**
 * This function filters a list of invoices by the amount of the invoice.
 *
 * @param invoiceList The list of invoices to filter
 * @param amountSearch The amount to filter by, as a string, e.g. "100.00"
 * @returns The filtered list of invoices
 */
export const filterAmounts = (invoiceList: InvoiceListData, amountSearch: string) =>
  invoiceList.filter(({ total }) => total.toFixed(2) == parseFloat(amountSearch).toFixed(2));

export const filterBalanceDues = (invoiceList, balanceDueSearch) =>
  invoiceList.filter(({ total, paidAmount = 0 }) => {
    const balanceDue = total - paidAmount;

    // Special case to make it easier to search for 0 balances
    if (parseFloat(balanceDueSearch) === 0) return balanceDue === 0;

    return balanceDue.toFixed(2) == parseFloat(balanceDueSearch).toFixed(2);
  });

export const filterBillingInterval = (invoicePlans, billingInterval) =>
  invoicePlans.filter((invoicePlan) => invoicePlan.billingInterval === billingInterval);

export const filterStudents = (invoiceList: InvoiceListData, studentSearch) =>
  invoiceList.filter(({ student }) => doesQueryMatchStudent(studentSearch)(student));

/**
 * This function filters a list of invoices that are NOT archived.
 *
 * @param invoiceList The list of invoices to filter
 * @returns The filtered list of invoices that are NOT archived
 */
export const filterArchived = (invoiceList: InvoiceListData) => {
  if (!Array.isArray(invoiceList)) return invoiceList;
  return invoiceList.filter(({ isArchived }) => !isArchived);
};

export const filterStudentsAndParents = (invoicePlanList, studentSearch) =>
  invoicePlanList.filter(({ students }) => {
    return students.some((student) => doesQueryMatchStudent(studentSearch)(student));
  });

export const filterDates = (invoicePlanList, fieldName, dateRange, timezone) =>
  invoicePlanList.filter((invoicePlan) => {
    const dateToCompare = invoicePlan[fieldName];

    const [displayedStartDate, displayedEndDate] = dateRange.split('-');

    const startDateTimestamp = moment(displayedStartDate).tz(timezone, true).valueOf();

    if (!displayedEndDate?.trim() && moment(dateToCompare).tz(timezone, true).isSame(startDateTimestamp, 'day'))
      return true;

    const startDate = moment(displayedStartDate).tz(timezone, true).startOf('day').valueOf();
    const endDate = moment(displayedEndDate).tz(timezone, true).endOf('day').valueOf();

    if (dateToCompare >= startDate && dateToCompare <= endDate) return true;

    return false;
  });

export const getTranslatedOptionsObj = (option, t) => {
  const translatedObj = option.map((obj) => {
    return {
      ...obj,
      text: t(obj.text),
      label: t(obj.text),
    };
  });
  return translatedObj;
};
